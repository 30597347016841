export default [
  {
    name: "SearchMain",
    path: "/search",
    component: () => import("@/components/search/SearchMain"),
    children: [
      {
        name: "ReviewSearch",
        path: "review_search/:menuId",
        component: () => import("@/components/search/menu/ReviewSearch"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "GoodsSearch",
        path: "goods_search",
        component: () => import("@/components/search/menu/GoodsSearch"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ProviderSearch",
        path: "provider_search",
        component: () => import("@/components/search/menu/ProviderSearch"),
        meta: {
          loginState: true,
        },
      },
    ],
  },
];
