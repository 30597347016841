import axios from "axios";
import jScommon from "@/functions/jScommon";
import store from "@/store";
import router from "@/router";

const instance = axios.create({
  baseURL: "/",
  timeout: 30000,
});

/*
    1. 요청 인터셉터
    2개의 콜백 함수를 받습니다.
*/
instance.interceptors.request.use(
  function (config) {
    store.dispatch("common/setLoading", true);

    const [tokens] = jScommon.getToken();
    // 요청 성공 직전 호출됩니다.
    // axios 설정값을 넣습니다. (사용자 정의 설정도 추가 가능)
    config.headers["Authorization"] = `bearer ${tokens.accessToken}`;
    return config;
  },
  function (error) {
    // 요청 에러 직전 호출됩니다.
    store.dispatch("common/setLoading", false);
    console.log("error1", error);
    return Promise.reject(error);
  }
);

/*
    2. 응답 인터셉터
    2개의 콜백 함수를 받습니다.
*/
instance.interceptors.response.use(
  function (response) {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다. 
        .then() 으로 이어집니다.
    */
    store.dispatch("common/setLoading", false);
    console.log("http Response", response.data);
    return response;
  },

  async function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.    
    */

    // 토큰 갱신을 요청하기 전에 본래 요청했던 내용
    store.dispatch("common/setLoading", false);
    const orginalRequest = error.config;
    const [token, autoLogin] = jScommon.getToken();
    if (
      (error.response &&
        error.response.status === 401 &&
        token.refreshToken &&
        token.accessToken) ||
      (!store.state.member.isLogin && token.refreshToken && token.accessToken)
    ) {
      try {
        //accessToken 갱신
        const result = await getAccessToken();

        //저장소에 토큰 저장
        jScommon.setToken(result.data.data, autoLogin);

        //이전 요청에 새로운 accessToken 세팅
        instance.defaults.headers.common["Authorization"] =
          result.data.accessToken;

        //이전 요청 다시 axios로 요청
        return instance(orginalRequest);
      } catch (_error) {
        console.log("http refrech token error!");
        jScommon.expireToken(); // refresh token으로 accessToken을 못 구하면 storage 삭제

        console.log("_error", _error);
        if (error.response.status === 401) {
          router.push("/member/login"); // 토큰을 못 갱신하면 로그인 페이지로 이동
        }
        return Promise.reject(_error); // 최종에러로 빠져 나가기
      }
    }
    if (error.response.status === 401) {
      router.push("/member/login"); // 토큰을 못 갱신하면 로그인 페이지로 이동
    }
    return Promise.reject(error); // 최종에러로 빠져 나가기
  }
);
//refreshToken을 사용해서 accessToken을 다시 받아옴
async function getAccessToken() {
  try {
    const [tokens] = jScommon.getToken();
    const res = await axios.post("/ramm_api/member/reset_access_token", null, {
      headers: {
        Authorization: `bearer ${tokens.refreshToken}`,
      },
    });
    return res;
  } catch (error) {
    console.log("getAccessToken", error);
    //리플레시 토큰 실패 하면 삭제 처리
    localStorage.removeItem(process.env.VUE_APP_STORAGE_NAME);
    return error;
  }
}
export { instance, getAccessToken };
