<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_5">
      램 가게 입점하기<router-link to="" @click.native="close"
        ><img src="@/assets/images/sub/close.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="terms_common">
      <h4>램 가게 입점하기</h4>

      <!---->
      <p>
        램 가게에 판매자로 입점하실 사업자는 아래 절차를 밟아주세요. 현재는
        법인사업자만 판매자로 입점하실 수 있습니다.
      </p>
      <p><br /></p>

      <p class="cc_1">
        '리뷰하고 돈벌자' 홈페이지에서 [마이페이지]를 눌러주세요.
      </p>
      <p><br /></p>

      <p class="cc_2">
        '리뷰하고 돈벌자' 회원이 아닌 분은 [로그인] 페이지에서 [일반회원가입]을
        눌러 일반회원으로 가입해주세요.
      </p>
      <p><br /></p>

      <p class="cc_3">
        로그인 후 [마이페이지]에서 [리뷰어/판매자 채널 등록] > [판매자 채널 등록
        신청하기]를 눌러주세요.
      </p>
      <p></p>
      <div class="img">
        <img src="@/assets/images/sub/template_01.jpg" alt="/" />
      </div>
      <br />

      <p class="cc_4">
        [램 가게 입점 신청] 양식이 뜨면 빈칸을 모두 채우고 필요한 서류를 첨부한
        후 [램 가게 입점 신청하기] 버튼을 눌러주세요.
      </p>
      <p><br /></p>

      <p class="cc_5">
        '리뷰하고 돈벌자' 본사의 심사 후 램 가게 입점 신청이 승인되면 안내
        메일이 발송됩니다.
      </p>
      <p><br /></p>

      <p class="cc_6">
        안내 메일을 열어 ['램 가게 관리자 페이지' 가기] 버튼을 누르세요. 그러면
        [램 가게 판매자 센터] 페이지로 이동합니다. 본사가 부여한 ID와 임시
        비밀번호로 로그인하세요. 램 가게 판매자 센터는 PC로 이용해주세요.
      </p>
      <p></p>
      <div class="img">
        <img src="@/assets/images/sub/template_02.jpg" alt="/" />
      </div>
      <br />

      <p class="cc_7">로그인 후 firstmall 메뉴 중에서 [관리자]를 눌러주세요.</p>
      <p></p>
      <div class="img">
        <img src="@/assets/images/sub/template_03.jpg" alt="/" />
      </div>
      <br />

      <p class="cc_8">
        [관리자 리스트]의 [대표운영자] 항목에서 [수정]을 눌러주세요.
      </p>
      <p></p>
      <div class="img">
        <img src="@/assets/images/sub/template_04.jpg" alt="/" />
      </div>
      <br />

      <p class="cc_9">
        [정산] 항목의 [입금 계좌 정보] 란에 '은행','예금주','계좌번호'를
        입력하고 '계좌사본'을 첨부한 후 위쪽의 [저장] 버튼을 눌러주세요.
        계좌정보가 정확하지 않으면 정산이 제대로 이루어질 수 없으니, 정확한
        정보를 입력해주세요.
      </p>
      <p></p>
      <div class="img">
        <img src="@/assets/images/sub/template_05.jpg" alt="/" />
      </div>
      <br />

      <p class="cc_10">
        스마트폰으로 '리뷰하고 돈벌자' [마이페이지]를 눌러보세요. 입점 신청이
        승인되면, [채널전환]의 [판매자 채널] 란에 입점사 상호가 생성됩니다.
        입점사 상호를 누르면 '일반회원 채널'이 '판매자 채널'로 전환되고, 판매자
        회원은 입점사에 필요한 다양한 기능들을 사용할 수 있습니다.
      </p>
      <p></p>
    </div>

    <!---->
  </div>
</template>

<script>
export default {
  props: {
    isModals: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
  metaInfo() {
    return {
      title: `${process.env.VUE_APP_APP_NAME}`,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "author",
          name: "author",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`,
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:image", property: "og:image", content: "" },
      ],
    };
  },
  methods: {
    close() {
      if (!this.isModals) {
        this.$router.back();
        return false;
      }
      this.$emit("open", "close");
      this.$store.dispatch("common/setFooter", {
        viewFooter: true,
      });
    },
  },
};
</script>

<style></style>
