import { member as call } from "@/api/call";
import jwtDecode from "jwt-decode";
import jScommon from "@/functions/jScommon";

export default {
  // store 에서 module 로 사용하기 위해 설정
  namespaced: true,
  state: () => ({
    isLogin: false,
    userid: null,
    email: null,
    result: false,
    autoLogin: false,
    msg: null,
    cellphone: null,
    old_cellphone: null,
    authNumber: null,
    tryCnt: 0,
    limitCnt: 0,
    isCheckPhone: false,
    isAuthNumber: false,
    totalCheck: false,
    mailing: false,
    sms: false,
    service: false,
    finance: false,
    privacy: false,
    over14: false,
    sEncData: "",
    authData: {},
    registDate: "",
    alarmCnt: 0,
  }),
  getters: {},
  mutations: {
    updateUserInfo(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    userInfoReset(state) {
      state.isLogin = false;
      state.userid = null;
      state.email = null;
      state.result = false;
      state.autoLogin = false;
      state.msg = false;
      state.cellphone = null;
      state.old_cellphone = null;
      state.authNumber = null;
      state.tryCnt = 0;
      state.limitCnt = 0;
      state.isCheckPhone = false;
      state.isAuthNumber = false;
      state.totalCheck = false;
      state.mailing = false;
      state.sms = false;
      state.service = false;
      state.finance = false;
      state.privacy = false;
      state.over14 = false;
      state.sEncData = "";
      state.authData = {};
      state.registDate = "";
      state.alarmCnt = 0;
    },
    updateCellphoneReset(state) {
      state.old_cellphone = null;
      state.isCheckPhone = false;
      state.isAuthNumber = false;
      state.cellphone = null;
      state.authNumber = null;
      state.tryCnt = 0;
      state.limitCnt = 0;
    },
    clearAuthData(state) {
      state.authData = {};
    },
  },
  actions: {
    async login({ state, commit }, payload) {
      try {
        const res = await call.login(payload);
        const { result, data, msg } = res.data;
        if (result) {
          const userInfo = jwtDecode(data.accessToken);
          const tokens = {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          };
          //로그인 상태 여부 업데이트
          commit("updateUserInfo", {
            isLogin: true,
            userid: userInfo.userid,
            autoLogin: payload.autoLogin,
            msg,
          });
          //로그인 하면 회원정보 가져오기
          commit(
            "mypage/updateData",
            { myInfo: data.user_data },
            { root: true }
          );
          //로그인 하면서 받아온 채널을 세팅
          commit("mypage/updateChannel", data.user_data.channel, {
            root: true,
          });
          // if (state.autoLogin) {
          //   // 자동로그인 이면 로컬스토리지에 저장
          //   localStorage.setItem(
          //     process.env.VUE_APP_STORAGE_NAME,
          //     JSON.stringify(tokens)
          //   );
          // } else {
          //   sessionStorage.setItem(
          //     // 기본은 세션스토리지에 저장
          //     process.env.VUE_APP_STORAGE_NAME,
          //     JSON.stringify(tokens)
          //   );
          // }
          if (state.autoLogin) {
            localStorage.setItem("autoLogin", 1);
          }
          localStorage.setItem(
            process.env.VUE_APP_STORAGE_NAME,
            JSON.stringify(tokens)
          );
        } else {
          commit("updateUserInfo", {
            msg,
            result: false,
          });
        }
      } catch (error) {
        console.error("User login error:", error);
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async loginState({ commit }) {
      //로그인 상테를 체크한다.
      try {
        const res = await call.loginState();
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateUserInfo", {
            isLogin: true,
            alarmCnt: data.alarm_cnt,
          });
        } else {
          commit("updateUserInfo", {
            isLogin: false,
          });
        }
      } catch (error) {
        console.log("User loginState error: ", error);
        commit("updateUserInfo", {
          isLogin: false,
        });
      }
    },
    async loginCheck({ commit, dispatch }) {
      //로그인 상테를 체크한다.
      try {
        const res = await call.loginCheck();
        const { result, msg, alarm_cnt } = res.data;
        const [tokens, autoLogin] = jScommon.getToken();
        const userInfo = jwtDecode(tokens.accessToken);
        commit("updateUserInfo", {
          user_name: userInfo.user_name,
          isLogin: true,
          userid: userInfo.userid,
          autoLogin: autoLogin,
          alarmCnt: alarm_cnt,
        });
      } catch (error) {
        console.log("User loginCheck error: ", error);
        dispatch("logOut");
      }
    },

    async getAuthNumber({ state, commit }, payload) {
      commit("updateUserInfo", {
        old_cellphone: payload.cellphone,
      });
      try {
        const res = await call.authPhone(payload);
        const { result, msg, data } = res.data;
        console.log("휴대폰인증 번호 :", data);
        if (result) {
          commit("updateUserInfo", {
            msg,
            isAuthNumber: true,
            tryCnt: parseInt(data.try_cnt) + 1,
            limitCnt: data.limit_cnt,
          });
        } else {
          commit("updateUserInfo", {
            msg,
            isAuthNumber: state.isAuthNumber ? true : false, //인증번호를 한번이라도 정상적으로 받았으면 true
            old_cellphone: null, //인증 실패했을때는 동일번호로 다시 인증번호 받을 수 있게
          });
        }
      } catch (error) {
        console.log("User getAuthNumber error:", error);
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          isAuthNumber: false,
        });
      }
    },

    async authPhoneConfirm({ state, commit }, payload) {
      try {
        const res = await call.authPhoneConfirm(payload);
        const { result, msg } = res.data;
        console.log("휴대폰번호 인증결과 :", res);

        commit("updateUserInfo", {
          msg,
          isCheckPhone: result,
          cellphone: payload.cellphone,
          authNumber: payload.authNumber,
        });
      } catch (error) {
        console.log("User authPhoneConfirm error:", error);
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          isCheckPhone: false,
          cellphone: null,
          authNumber: null,
        });
      }
    },
    async registerOk({ rootState, commit }, payload) {
      const setData = {
        ...payload,
        cellphone: rootState.member.cellphone,
        authNumber: rootState.member.authNumber,
        sms: rootState.member.sms,
        mailing: rootState.member.mailing,
      };
      try {
        const res = await call.registerOk(setData);
        const { result, msg } = res.data;
        if (result) {
          commit("updateUserInfo", {
            msg: msg.replace(/(<([^>]+)>)/g, ""),
            result,
          });
        } else {
          commit("updateUserInfo", {
            msg: msg.replace(/(<([^>]+)>)/g, ""),
            result: false,
          });
        }
      } catch (error) {
        console.log("registerOk error:", error);
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async userAuthPhone({ commit }) {
      try {
        const res = await call.userAuthPhone();
        const { result, msg, data } = res.data;
        commit("updateUserInfo", {
          sEncData: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("userAuthPhone");
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async userAuthPhoneCheck({ commit }, payload) {
      try {
        const res = await call.userAuthPhoneCheck(payload);
        const { result, msg, data } = res.data;
        commit("updateUserInfo", {
          authData: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("userAuthPhoneCheck");
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async reviewerWithdraw({ commit }) {
      try {
        const res = await call.reviewerWithdraw();
        const { result, msg, data } = res.data;
        commit("updateUserInfo", {
          msg,
          result,
        });
      } catch (error) {
        console.log("reviewerWithdraw");
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async sellerWithdraw({ commit }) {
      try {
        const res = await call.sellerWithdraw();
        const { result, msg, data } = res.data;
        commit("updateUserInfo", {
          msg,
          result,
        });
      } catch (error) {
        console.log("sellerWithdraw");
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async checkSellerWithdraw({ commit }) {
      try {
        const res = await call.checkSellerWithdraw();
        const { result, msg, data } = res.data;
        commit("updateUserInfo", {
          msg,
          result,
        });
      } catch (error) {
        console.log("checkSellerWithdraw");
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async withdrawal({ commit }) {
      try {
        const res = await call.withdrawal();
        const { result, msg, data } = res.data;
        commit("updateUserInfo", {
          msg,
          result,
        });
      } catch (error) {
        console.log("withdrawal");
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getFindAuthNumber({ state, commit }, payload) {
      commit("updateUserInfo", {
        old_cellphone: payload.cellphone,
      });
      try {
        const res = await call.getFindAuthNumber(payload);
        const { result, msg, data } = res.data;
        console.log("휴대폰인증 번호 :", data);
        if (result) {
          commit("updateUserInfo", {
            msg,
            isAuthNumber: true,
            tryCnt: parseInt(data.try_cnt) + 1,
            limitCnt: data.limit_cnt,
          });
        } else {
          commit("updateUserInfo", {
            msg,
            isAuthNumber: state.isAuthNumber ? true : false, //인증번호를 한번이라도 정상적으로 받았으면 true
            old_cellphone: null, //인증 실패했을때는 동일번호로 다시 인증번호 받을 수 있게
          });
        }
      } catch (error) {
        console.log("User getFindAuthNumber  error:", error);
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          isAuthNumber: false,
        });
      }
    },
    async authFindPhoneConfirm({ commit }, payload) {
      try {
        const res = await call.authFindPhoneConfirm(payload);
        const { result, msg, data } = res.data;
        console.log("휴대폰번호 인증결과 :", res);
        commit("updateUserInfo", {
          msg,
          result,
          userid: data.userid,
          email: data.email,
          registDate: data.regist_date,
          isCheckPhone: result,
          cellphone: payload.cellphone,
          authNumber: payload.authNumber,
        });
      } catch (error) {
        console.log("User authFindIdPhoneConfirm error:", error);
        commit("updateUserInfo", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          isCheckPhone: false,
          cellphone: null,
          authNumber: null,
        });
      }
    },
    //특정 데이터만 초기화
    userDataClear({ commit }, payload) {
      commit("updateUserInfo", { ...payload });
    },
    //초기화
    clearUserInfoAll({ commit }) {
      commit("userInfoReset");
    },
    //인증정보 초기화
    clearAuthData({ commit }) {
      commit("clearAuthData");
    },
    //토큰 지우면서 유저 정보 초기화
    logOut({ commit }) {
      localStorage.clear();
      sessionStorage.clear();
      commit("mypage/clearMyInfo", null, { root: true });
      commit("userInfoReset");
    },
  },
};
