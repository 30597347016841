import Vue from "vue";
import VueRouter from "vue-router";
import member from "@/router/member";
import mypage from "@/router/mypage";
import shop from "@/router/shop";
import review from "@/router/review";
import channel from "@/router/channel";
import wish from "@/router/wish";
import rank from "@/router/rank";
import search from "@/router/search";
import introduce from "@/router/introduce";
import cs from "@/router/cs";
import Main from "@/views/Main";
import store from "@/store";
import { getAccessToken } from "@/api/http";
import jwtDecode from "jwt-decode";
Vue.use(VueRouter);

const routes = [
  {
    name: "Main",
    path: "/",
    component: Main,
  },
  ...member,
  ...mypage,
  ...shop,
  ...review,
  ...channel,
  ...wish,
  ...rank,
  ...search,
  ...introduce,
  ...cs,
  {
    name: "NotFound",
    path: "*",
    component: Main,
    meta: {
      notFound: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0); //라우트 화면 이동시 항상 페이지 최상단으로 이동
  },
});

const checkLoginInfo = async () => {
  const userinfo = jwtDecode(
    localStorage.getItem(process.env.VUE_APP_STORAGE_NAME)
  );
  console.log(userinfo);
  if (
    !store.state.mypage.myInfo.member_seq &&
    !store.state.mypage.myInfo.userid
  ) {
    await store.dispatch("mypage/getMypageMyinfo");
    if (
      store.state.mypage.myInfo.member_seq !== userinfo.member_seq ||
      store.state.mypage.myInfo.userid !== userinfo.userid
    ) {
      store.dispatch("member/logOut");
      Vue.$toast.default("로그아웃 되었습니다.");
      VueRouter.push("/member/login").catch(() => {});
      //앱에 로그아웃됨을 알려줌
      setLogOutSuccess();
    }
  } else {
    if (
      store.state.mypage.myInfo.member_seq !== userinfo.member_seq ||
      store.state.mypage.myInfo.userid !== userinfo.userid
    ) {
      store.dispatch("member/logOut");
      Vue.$toast.default("로그아웃 되었습니다.");
      VueRouter.push("/member/login").catch(() => {});
      //앱에 로그아웃됨을 알려줌
      setLogOutSuccess();
    }
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.meta.notFound) {
    Vue.$toast.default("찾으시는 페이지가 존재하지 않습니다.");
    next("/");
    return false;
  }
  if (to.meta.auth) {
    await store.dispatch("member/loginCheck");
    if (store.state.member.isLogin) {
      //로그인이 되어있다면 회원정보를 가져와본다
      checkLoginInfo();
      if (to.meta.isReviewer) {
        if (
          store.state.mypage.myInfo.channel !== "reviewer" &&
          !store.state.mypage.channel.reviewer
        ) {
          Vue.$toast.default("리뷰어만 이용가능한 메뉴 입니다.");
          next("/mypage/my_info");
          return false;
        }
      }
      if (to.meta.isSeller) {
        if (
          store.state.mypage.myInfo.channel !== "seller" &&
          !store.state.mypage.channel.seller
        ) {
          Vue.$toast.default("판매자만 이용가능한 메뉴 입니다.");
          next("/mypage/my_info");
          return false;
        }
      }
      if (to.meta.hasReviewer) {
        if (store.state.mypage.myInfo.is_reviewer === "Y") {
          Vue.$toast.default("리뷰어 채널이 존재 합니다.");
          next("/mypage/my_info");
          return false;
        }
      }
      next();
    } else {
      store.dispatch("common/setReferer", to); //레퍼러 설정
    }
  }
  if (to.meta.loginState) {
    //로그인이 안되어 있다면...
    if (!store.state.member.isLogin) {
      //토큰으로 로그인 여부 확인  401을 대신 ture, false
      await store.dispatch("member/loginState", { log: "1" });

      //토큰이 정상적이면 로그인 된다.
      if (store.state.member.isLogin) {
        //로그인이 되어있고 회원 정보가 없을때 정보 가져오기
        checkLoginInfo();
      } else {
        console.log(store.state.member.isLogin, "login false");

        //로그인이 안되면 reflash token 확인
        const tokens = JSON.parse(
          localStorage.getItem(process.env.VUE_APP_STORAGE_NAME)
        );
        if (tokens) {
          // 토큰이 있다면 리플레시 토큰 시도
          let newToken = null;
          getAccessToken()
            .then(async (value) => {
              newToken = value.data.data;

              //이전 토큰 삭제
              localStorage.removeItem(process.env.VUE_APP_STORAGE_NAME);

              //새로운 토큰으로 변경
              localStorage.setItem(
                process.env.VUE_APP_STORAGE_NAME,
                JSON.stringify(newToken)
              );

              // 로그인 상태 체크
              await store.dispatch("member/loginState", { log: "2" });

              if (store.state.member.isLogin) {
                //로그인 되어있으면 내정보 가져오기
                checkLoginInfo();
              } else {
                //로그인이 안되면 로그인 페이지로 이동
                next("/member/login");
              }
            })
            .catch((value) => {
              //로그인 실패 시...
              console.log("token reflash filed", value);
              localStorage.removeItem(process.env.VUE_APP_STORAGE_NAME);
            });
        }
      }
    }

    //이게 없으면 화면안에 funciton 을 클릭할때   scrollBehavior()  함수를 타게 될때가 있음.
    // console.log(to, from);
    if (to.fullPath.split("?").length > 1 || to.path !== from.path) {
      next();
    }
    return false;
  }
  next();
});

export default router;
