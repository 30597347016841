export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    viewFooter: true,
    rank: false,
    wish: false,
    alarm: false,
    mypage: false,
    referer: null,
  }),
  mutations: {
    setLoading(state, isTrue) {
      state.isLoading = isTrue;
    },
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    resetFooter(state) {
      state.viewFooter = true;
      state.rank = false;
      state.wish = false;
      state.alarm = false;
      state.mypage = false;
    },
  },
  getters: {},
  actions: {
    setLoading({ commit }, isTrue) {
      commit("setLoading", isTrue);
    },
    setFooter({ commit }, payload) {
      document.querySelector("body").style.overflow = "";
      commit("resetFooter");
      commit("updateData", {
        ...payload,
      });
    },
    setReferer({ commit }, payload) {
      commit("updateData", {
        referer: payload,
      });
    },
  },
};
