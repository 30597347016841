export default [
  {
    name: "Login",
    path: "/member/login",
    component: () => import("@/views/member/Login"),
  },
  {
    name: "MemberRegister",
    path: "/member/member_register",
    component: () => import("@/views/member/MemberRegister"),
  },
  {
    name: "AuthPhone",
    path: "/member/auth_phone",
    component: () => import("@/views/member/AuthPhone"),
  },
  {
    name: "Terms",
    path: "/member/terms",
    component: () => import("@/views/member/Terms"),
  },
  {
    name: "ReviewerWithdraw",
    path: "/member/reviewer_withdraw",
    component: () => import("@/views/member/ReviewerWithdraw"),
  },
  {
    name: "SellerWithdraw",
    path: "/member/seller_withdraw",
    component: () => import("@/views/member/SellerWithdraw"),
  },
  {
    name: "Withdraw",
    path: "/member/withdraw",
    component: () => import("@/views/member/Withdraw"),
  },
  {
    name: "FindeId",
    path: "/member/find_id",
    component: () => import("@/views/member/FindId"),
  },
  {
    name: "FindIdResult",
    path: "/member/findid_result",
    component: () => import("@/views/member/FindIdResult"),
  },
  {
    name: "FindPw",
    path: "/member/find_pw",
    component: () => import("@/views/member/FindPw"),
  },
  {
    name: "FindPwResult",
    path: "/member/findpw_result",
    component: () => import("@/views/member/FindPwResult"),
  },
];
