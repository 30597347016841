export default [
  {
    name: "WishList",
    path: "/wish",
    component: () => import("@/views/wish/WishList"),
    meta: {
      auth: true,
    },
  },
  {
    name: "WishListType",
    path: "/wish/:menuName",
    component: () => import("@/views/wish/WishList"),
    meta: {
      auth: true,
    },
  },
];
