import { instance } from "@/api/http";

export default {
  bestGoodList(payload = {}) {
    return instance({
      url: "/ramm_api/shop",
      method: "post",
      data: payload,
    });
  },
  getGoods(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_goods?no=${payload}`,
      method: "get",
    });
  },
  getMainCategory(payload = {}) {
    return instance({
      url: "/ramm_api/shop/get_main_categories",
      method: "post",
      data: payload,
    });
  },
  moreOption(payload = {}) {
    return instance({
      url: `/ramm_api/shop/option?${payload}`,
      method: "get",
    });
  },
  getMarketMainList(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_maket_main_list`,
      method: "post",
      data: payload,
    });
  },
  getCategories(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_category_list`,
      method: "post",
      data: payload,
    });
  },
  getGoodsLowCategory(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_goods_low_category`,
      method: "post",
      data: payload,
    });
  },
  getMarketSubList(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_maket_sub_list`,
      method: "post",
      data: payload,
    });
  },
  getGoodsList(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_market_goods_list`,
      method: "post",
      data: payload,
    });
  },
  getGoodsDetail(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_goods_detail`,
      method: "post",
      data: payload,
    });
  },
  getQnaList(payload = {}) {
    return instance({
      url: `/ramm_api/board/get_qna_list`,
      method: "post",
      data: payload,
    });
  },
  qnaProcess(payload = {}) {
    return instance({
      url: `/ramm_api/board_process`,
      method: "post",
      data: payload,
    });
  },
  setAdd(payload = {}) {
    return instance({
      url: `/ramm_api/order/set_add`,
      method: "post",
      data: payload,
    });
  },
  getMiniShop(payload = {}) {
    return instance({
      url: `/ramm_api/shop/get_mini_shop`,
      method: "post",
      data: payload,
    });
  },
  getSellerInfo(payload = {}) {
    return instance({
      url: `/ramm_api/shop/seller_info`,
      method: "post",
      data: payload,
    });
  },
};
