export default [
  {
    name: "ReviewRank",
    path: "/reviewer_rank",
    component: () => import("@/views/rank/ReviewRank"),
  },
  {
    name: "ReviewRankSeq",
    path: "/reviewer_rank/:main_seq",
    component: () => import("@/views/rank/ReviewRank"),
  },
];
