export default [
  {
    name: "ReviewerRegister",
    path: "/channel/reviewer_register",
    component: () => import("@/views/channel/ReviewerRegister"),
    meta: {
      auth: true,
      hasReviewer: true,
    },
  },
  {
    name: "SellerRegister",
    path: "/channel/seller_register",
    component: () => import("@/views/channel/SellerRegister"),
    meta: {
      auth: true,
      hasSeller: true,
    },
  },
];
