import { review as call } from "@/api/call";
export default {
  namespaced: true,
  state: () => ({
    mainMenuList: [],
    subMenuList: [],
    reviewMenuList: [],
    listArray: [],
    totalCntOwn: 0,
    totalCntTwo: 0,
    totalPage: 0,
    replyTotalPage: 0,
    limit: 0,
    result: false,
    msg: null,
    satisfaction: 0,
    incentive: 0,
    evaluation: 0,
    trustScore: 0,
    mainImage: "",
    newsContents: "",
    categoryTitle: "",
    imgUrl: "",
    goodsInfo: "",
    categoryName: [],
    replyArrayList: [],
    newsMenuList: [],
    realTimeNewsList: [],
    replyLimit: 0,
    newsCount: 0,
    reviewSeq: 0,
    rank: 0,
    reivewData: null,
    categoryId: 0,
    reviewerNick: "",
    reviewerImg: "",
    top3List: {},
    endCategory: [],
  }),
  mutations: {
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key === "listArray" || key === "replyArrayList") {
          state[key] = [...state[key], ...payload[key]];
        } else {
          state[key] = payload[key];
        }
      });
    },
    resetStore(state) {
      state.mainMenuList = [];
      state.subMenuList = [];
      state.reviewMenuList = [];
      state.listArray = [];
      state.totalCntOwn = 0;
      state.totalCntTwo = 0;
      state.totalPage = 0;
      state.replyTotalPage = 0;
      state.limit = 0;
      state.replyLimit = 0;
      state.result = false;
      state.msg = null;
      state.satisfaction = 0;
      state.incentive = 0;
      state.evaluation = 0;
      state.trust_score = 0;
      state.mainImage = "";
      state.newsContents = "";
      state.categoryTitle = "";
      state.imgUrl = "";
      state.goodsInfo = "";
      state.categoryName = [];
      state.replyArrayList = [];
      state.newsMenuList = [];
      state.realTimeNewsList = [];
      state.newsCount = 0;
      state.reviewSeq = 0;
      state.reivewData = null;
      state.categoryId = 0;
      state.reviewerNick = "";
      state.reviewerImg = "";
      state.top3List = {};
      state.endCategory = [];
    },
    listReset(state) {
      state.listArray = [];
    },
    replyListRest(state) {
      state.replyArrayList = [];
    },
    reviewStateReset(state) {
      state.listArray = [];
      state.totalCntOwn = 0;
      state.totalCntTwo = 0;
      state.totalPage = 0;
      state.replyTotalPage = 0;
      state.limit = 0;
      state.imgUrl = "";
      state.categoryName = [];
    },
    addOptions(state, payload) {
      state.goodsInfo.options = {
        option: payload.option_category,
        option1: payload.option_category1,
        option2: payload.option_category2,
        title1: payload.option_title1,
        title2: payload.option_title2,
      };
    },
    updateWish(state, payload) {
      if (payload.reviewer !== null) {
        state.listArray.map((list, index) => {
          if (parseInt(list.member_seq) === parseInt(payload.reviewer)) {
            state.listArray[index].wish_yn_reviwer = payload.wishYn;
          }
        });
      } else if (payload.seq !== null) {
        if (payload.type === "shop") {
          state.listArray.map((list, index) => {
            if (parseInt(list.provider_seq) === parseInt(payload.seq)) {
              state.listArray[index].mini_shop_wish = payload.wishYn;
            }
          });
        } else {
          let index = state.listArray.findIndex(
            (list) => list.seq === payload.seq
          );
          state.listArray[index].wish_yn = payload.wishYn;
        }
      }
    },
    updateTop3Wish(state, payload) {
      Object.keys(state.top3List).forEach((key) => {
        state.top3List[key].map((list) => {
          if (parseInt(list.member_seq) === parseInt(payload.reviewer)) {
            list.wish_yn_reviwer = payload.wishYn;
          }
        });
      });
    },
    updateScore(state, payload) {
      let index = state.listArray.findIndex(
        (list) => list.seq === payload.review_seq
      );
      state.listArray[index].review_score = payload.review_score;
      state.listArray[index].score = payload.score;
      state.listArray[index].re_trust_score = payload.re_trust_score
        ? payload.re_trust_score
        : state.listArray[index].re_trust_score;
      state.listArray[index].trust_score = payload.trust_score
        ? payload.trust_score
        : state.listArray[index].trust_score;
    },
    updateReplyCnt(state, payload) {
      const index = state.listArray.findIndex(
        (list) => list.seq === payload.seq
      );
      if (payload.type === "plus") {
        state.replyArrayList.splice(0, 0, payload.data);
        state.listArray[index].comment_cnt =
          parseInt(state.listArray[index].comment_cnt) + 1;
      } else {
        //삭제 minus 일 경우 배열에서 삭제
        if (!payload.parent_seq) {
          const replyIndex = state.replyArrayList.findIndex(
            (reply) => parseInt(reply.seq) === parseInt(payload.reply_seq)
          );
          state.replyArrayList.splice(replyIndex, 1);
          state.listArray[index].comment_cnt =
            parseInt(state.listArray[index].comment_cnt) - 1;
        }
      }
    },
    updateReReply(state, payload) {
      const parentSeq = payload.parent_seq;
      if (payload.type === "plus") {
        const index = state.replyArrayList.findIndex(
          (reply) => parseInt(reply.parent_seq) === parseInt(parentSeq)
        );
        const position = parseInt(index) + payload.data.length;
        state.replyArrayList.splice(
          position,
          0,
          payload.data[payload.data.length - 1]
        );
        const parnetIndex = state.replyArrayList.findIndex(
          (reply) => parseInt(reply.seq) === parseInt(parentSeq)
        );
        state.replyArrayList[parnetIndex].child_cnt =
          parseInt(state.replyArrayList[parnetIndex].child_cnt) + 1;
      } else {
        const index = state.replyArrayList.findIndex(
          (reply) => parseInt(reply.seq) === parseInt(payload.child_seq)
        );
        // console.log(state.replyArrayList[index]);
        state.replyArrayList.splice(index, 1);
        const parnetIndex = state.replyArrayList.findIndex(
          (reply) => parseInt(reply.seq) === parseInt(parentSeq)
        );
        state.replyArrayList[parnetIndex].child_cnt =
          parseInt(state.replyArrayList[parnetIndex].child_cnt) - 1;
      }
    },
    updateReply(state, payload) {
      const index = state.replyArrayList.findIndex(
        (reply) => parseInt(reply.seq) === parseInt(payload.seq)
      );
      state.replyArrayList[index].content = payload.content;
    },
    updateNewsMeta(state, payload) {
      const index = state.listArray.findIndex(
        (list) => parseInt(list.seq) === parseInt(payload.seq)
      );
      state.listArray[index].meta = payload.newsContents;
    },
    removeReview(state, payload) {
      const saleCnt = parseInt(payload.saleCnt);
      if (saleCnt > 0) {
        const index = state.listArray.findIndex(
          (list) => parseInt(list.seq) === parseInt(payload.seq)
        );
        state.listArray[index]["sale_stop_yn"] = "Y";
      } else {
        const index = state.listArray.findIndex(
          (list) => parseInt(list.seq) === parseInt(payload.seq)
        );
        state.listArray.splice(index, 1);
      }
    },
  },
  getters: {},
  actions: {
    async getMainMenu({ commit }) {
      try {
        const res = await call.getMainMenu();
        const { result, msg, data } = res.data;
        commit("updateData", {
          mainMenuList: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getMainMenu", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getSubMenu({ commit }, payload) {
      try {
        const res = await call.getSubMenu(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          subMenuList: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getSubMenu", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReviewMenu({ commit }) {
      try {
        const res = await call.getReviewMenu();
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewMenuList: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getReviewMenu", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReviewList({ commit }, payload) {
      try {
        const res = await call.getReviewList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("listReset");
          }
          commit("updateData", {
            listArray: data.list,
            totalCntOwn: data.total_cnt_one,
            totalCntTwo: data.total_cnt_two,
            totalPage: data.total_page,
            msg,
            result,
            limit: data.limit,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getReviewList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReviewEvaluation({ commit }) {
      try {
        commit("listReset");
        const res = await call.getReviewEvaluation();
        const { result, msg, data } = res.data;
        if (data) {
          commit("updateData", {
            satisfaction: data.review_satisfaction || 0,
            incentive: data.purchase_incentive || 0,
            evaluation: data.goods_service_evaluation || 0,
            trustScore: data.trust_score || 0,
            listArray: data.list || [],
          });
        }
      } catch (error) {
        console.log("getReviewEvaluation", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async submitMainImg({ commit }, payload) {
      try {
        const res = await call.submitMainImg(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            mainImage: data,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("submitMainImg", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getMetaData({ commit }, payload) {
      try {
        const res = await call.getMetaData(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            newsContents: data,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("submitMainImg", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getCategoryTitle({ commit }, payload) {
      try {
        const res = await call.getCategoryTitle(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            categoryTitle: data,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("getCategoryTitle", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setNewsReviewRegister({ commit }, payload) {
      try {
        const res = await call.setNewsReviewRegister(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewSeq: data.review_seq,
          msg,
          result,
        });
      } catch (error) {
        console.log("setNewsReviewRegister", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setNormalGoodsReviewRegister({ commit }, payload) {
      try {
        const res = await call.setNormalGoodsReviewRegister(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewSeq: data.review_seq,
          categoryId: data.category_id,
          msg,
          result,
        });
      } catch (error) {
        console.log("setNormalGoodsReviewRegister", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setSellerGoodsReviewRegister({ commit }, payload) {
      try {
        const res = await call.setSellerGoodsReviewRegister(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewSeq: data.review_seq,
          categoryId: data.category_id,
          msg,
          result,
        });
      } catch (error) {
        console.log("setSellerGoodsReviewRegister", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setProGoodsReviewRegister({ commit }, payload) {
      try {
        const res = await call.setProGoodsReviewRegister(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewSeq: data.review_seq,
          categoryId: data.category_id,
          msg,
          result,
        });
      } catch (error) {
        console.log("setProGoodsReviewRegister", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async editorImgUpload({ commit }, payload) {
      try {
        const res = await call.editorImgUpload(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          imgUrl: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("editorImgUpload", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getGoodsInfo({ commit }, payload) {
      try {
        const res = await call.getGoodsInfo(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          goodsInfo: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getGoodsInfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReviewGoodsList({ commit }, payload) {
      try {
        const res = await call.getReviewGoodsList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("listReset");
          }
          commit("updateData", {
            listArray: data.list,
            totalPage: data.total_page,
            categoryName: data.category_name.category,
            rank: data.rank,
            reviewerNick: data.reviewer_nick,
            reviewerImg: data.reviewer_img,
            msg,
            result,
            limit: data.limit,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getReviewGoodsList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setMakeReviewGoodsScore({ commit }, payload) {
      try {
        const res = await call.setMakeReviewGoodsScore(payload);
        const { result, msg, data } = res.data;
        if (result) {
          payload.re_trust_score = data.re_trust_score;
          payload.trust_score = data.trust_score;
          commit("updateScore", payload);
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("setMakeReviewGoodsScore", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setMakeReviewNewsScore({ commit }, payload) {
      try {
        const res = await call.setMakeReviewNewsScore(payload);
        const { result, msg, data } = res.data;
        if (result) {
          payload.re_trust_score = data.re_trust_score;
          payload.trust_score = data.trust_score;
          commit("updateScore", payload);
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("setMakeReviewGoodsScore", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async writeReply({ commit }, payload) {
      try {
        const res = await call.writeReply(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          msg,
          result,
        });
        if (result) {
          //답글일경우 댓글 업데이트 안함.
          if (!payload.parent_seq) {
            commit("updateReplyCnt", {
              type: "plus",
              seq: payload.review_seq,
              data,
            });
          }
        }
      } catch (error) {
        console.log("writeReply", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReplyList({ commit }, payload) {
      try {
        const res = await call.getReplyList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("replyListRest");
          }
          commit("updateData", {
            replyArrayList: data.list,
            replyTotalPage: data.total_page,
            replyLimit: data.limit,
            msg,
            result,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getReplyList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReReplyList({ commit }, payload) {
      try {
        const res = await call.getReReplyList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            msg,
            result,
          });
          commit("updateReReply", {
            data,
            type: payload.type,
            child_seq: payload.child_seq,
            parent_seq: payload.parent_seq,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getReReplyList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setReplyDeclaration({ commit }, payload) {
      try {
        const res = await call.setReplyDeclaration(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateData", {
            msg,
            result,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("setReplyDeclaration", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getNewsMenu({ commit }) {
      try {
        const res = await call.getNewsMenu();
        const { result, msg, data } = res.data;
        commit("updateData", {
          newsMenuList: data.list,
          msg,
          result,
        });
      } catch (error) {
        console.log("getNewsMenu", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getRealTimeNews({ commit }) {
      try {
        const res = await call.getRealTimeNews();
        const { result, msg, data } = res.data;
        commit("updateData", {
          realTimeNewsList: data.list,
          msg,
          result,
        });
      } catch (error) {
        console.log("getRealTimeNews", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getNewsList({ commit }, payload) {
      try {
        const res = await call.getNewsList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("listReset");
          }
          commit("updateData", {
            listArray: data.list,
            totalPage: data.total_page,
            rank: data.rank,
            reviewerNick: data.reviewer_nick,
            reviewerImg: data.reviewer_img,
            msg,
            result,
            limit: data.limit,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getNewsList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getNewsTop3List({ commit }) {
      try {
        const res = await call.getNewsTop3List();
        const { result, msg, data } = res.data;
        if (result) {
          commit("listReset");
          commit("updateData", {
            top3List: data.list,
            msg,
            result,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getNewsTop3List", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setViewHit({ commit }, payload) {
      try {
        const res = await call.setViewHit(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          newsCount: data.cnt,
          msg,
          result,
        });
      } catch (error) {
        console.log("setViewHit", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setNewsHit({ commit }, payload) {
      try {
        const res = await call.setNewsHit(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("setNewsHit", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getGoodsNomalReview({ commit }, payload) {
      try {
        const res = await call.getGoodsNomalReview(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewData: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getGoodsNomalReview", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getGoodsProReview({ commit }, payload) {
      try {
        const res = await call.getGoodsProReview(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewData: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getGoodsProReview", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getNewsReview({ commit }, payload) {
      try {
        const res = await call.getNewsReview(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewData: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getNewsReview", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deleteReview({ commit }, payload) {
      try {
        const res = await call.deleteReview(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
        if (result) {
          commit("removeReview", payload);
        }
      } catch (error) {
        console.log("deleteReview", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getEndCategory({ commit }, payload) {
      commit("updateData", { endCategory: [] });
      try {
        const res = await call.getEndCategory(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          msg,
          result,
          endCategory: data.end_category,
        });
      } catch (error) {
        console.log("getEndCategory", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    updateWish({ commit }, payload) {
      commit("updateWish", payload);
    },
    updateTop3Wish({ commit }, payload) {
      commit("updateTop3Wish", payload);
    },
    reviewStateReset({ commit }) {
      commit("reviewStateReset");
    },
    resetStore({ commit }) {
      commit("resetStore");
    },
    listReset({ commit }) {
      commit("listReset");
    },
    addOptions({ commit }, payload) {
      commit("addOptions", payload);
    },
    updateReplyCnt({ commit }, payload) {
      commit("updateReplyCnt", payload);
    },
    updateReply({ commit }, payload) {
      commit("updateReply", payload);
    },
    updateNewsMeta({ commit }, payload) {
      commit("updateNewsMeta", payload);
    },
  },
};
