import { search as call } from "@/api/call";

export default {
  namespaced: true,
  state: () => ({
    result: false,
    msg: null,
    categoryArray: [],
    categoryPageCnt: 0,
    textCategoryArray: [],
    textCategoryPageCnt: 0,
    reviewerArray: [],
    reviewerPageCnt: 0,
    goodsCategoryArray: [],
    goodsCategoryPageCnt: 0,
    textTitleArray: [],
    textTitlePageCnt: 0,
    textGoodsArray: [],
    textGoodsPageCnt: 0,
    providerArray: [],
    providerPageCnt: 0,
    limit: 0,
    providerList: [],
    categoryList: [],
    catePages: {},
  }),
  getters: {},
  mutations: {
    resetSearch(state) {
      state.result = false;
      state.msg = null;
      state.categoryArray = [];
      state.categoryPageCnt = 0;
      state.textCategoryArray = [];
      state.textCategoryPageCnt = 0;
      state.reviewerArray = [];
      state.reviewerPageCnt = 0;
      state.goodsCategoryArray = [];
      state.goodsCategoryPageCnt = 0;
      state.textTitleArray = [];
      state.textTitlePageCnt = 0;
      state.textGoodsArray = [];
      state.textGoodsPageCnt = 0;
      state.providerArray = [];
      state.providerPageCnt = 0;
      state.limit = 0;
      state.providerList = [];
      state.categoryList = [];
      state.catePages = {};
    },
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key.includes("Array")) {
          // console.log(key, payload[key]);
          state[key] = [...state[key], ...payload[key]];
        } else {
          state[key] = payload[key];
        }
      });
    },
    providerList(state) {
      state.providerList = [];
      state.catePages = {};
    },
    updateProviderArray(state, payload) {
      const { category_code, data } = payload;
      // console.log(data);
      state.providerList[category_code]["result"].push(...data.list["result"]);
      state.providerList[category_code]["count"] = data.list["count"];
    },
  },
  actions: {
    async getTotalSearch({ commit }, payload) {
      try {
        const res = await call.getTotalSearch(payload);
        const { result, msg, data } = res.data;
        commit("resetSearch");
        commit("updateData", {
          categoryArray: data.category_array || [],
          categoryPageCnt: parseInt(data.category_page_cnt) || 0,
          textCategoryArray: data.text_category_array || [],
          textCategoryPageCnt: parseInt(data.text_category_page_cnt) || 0,
          reviewerArray: data.reviewer_array || [],
          reviewerPageCnt: parseInt(data.reviewer_page_cnt) || 0,
          goodsCategoryArray: data.goods_category_array || [],
          goodsCategoryPageCnt: parseInt(data.goods_category_page_cnt) || 0,
          textGoodsArray: data.text_goods_array || [],
          textGoodsPageCnt: parseInt(data.text_goods_page_cnt) || 0,
          providerArray: data.provider_array || [],
          providerPageCnt: parseInt(data.provider_page_cnt) || 0,
          textTitleArray: data.text_title_array || [],
          textTitlePageCnt: parseInt(data.text_title_page_cnt) || 0,
          limit: data.limit,
          msg,
          result,
        });
      } catch (error) {
        console.log("getTotalSearch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async categorySearch({ commit }, payload) {
      try {
        const res = await call.categorySearch(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          categoryArray: data.category_array || [],
          categoryPageCnt: parseInt(data.category_page_cnt) || 0,
        });
      } catch (error) {
        console.log("categorySearch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async textCategorySearch({ commit }, payload) {
      try {
        const res = await call.textCategorySearch(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          textCategoryArray: data.category_array || [],
          textCategoryPageCnt: parseInt(data.category_page_cnt) || 0,
        });
      } catch (error) {
        console.log("textCategorySearch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async textSerch({ commit }, payload) {
      try {
        const res = await call.textSerch(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          textTitleArray: data.text_title_array || [],
          textTitlePageCnt: parseInt(data.text_title_page_cnt) || 0,
        });
      } catch (error) {
        console.log("textSerch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async goodsCategorySearch({ commit }, payload) {
      try {
        const res = await call.goodsCategorySearch(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          goodsCategoryArray: data.goods_category_array || [],
          goodsCategoryPageCnt: parseInt(data.goods_category_page_cnt) || 0,
        });
      } catch (error) {
        console.log("goodsCategorySearch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async reviewerSerch({ commit }, payload) {
      try {
        const res = await call.reviewerSerch(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          reviewerArray: data.reviewer_array || [],
          reviewerPageCnt: parseInt(data.reviewer_page_cnt) || 0,
        });
      } catch (error) {
        console.log("reviewerSerch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async textGoodsSerch({ commit }, payload) {
      try {
        const res = await call.textGoodsSerch(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          textGoodsArray: data.text_goods_array || [],
          textGoodsPageCnt: parseInt(data.text_goods_page_cnt) || 0,
        });
      } catch (error) {
        console.log("textGoodsSerch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async providerSerch({ commit }, payload) {
      try {
        const res = await call.providerSerch(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          providerArray: data.provider_array || [],
          providerPageCnt: parseInt(data.provider_page_cnt) || 0,
        });
      } catch (error) {
        console.log("providerSerch", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getProviderList({ commit }, payload) {
      try {
        const res = await call.getProviderList(payload);
        const { result, msg, data } = res.data;
        commit("providerList");
        commit("updateData", {
          providerList: data.list,
          categoryList: data.category_list,
          limit: data.limit,
          msg,
          result,
        });
      } catch (error) {
        console.log("getProviderList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async moreProvider({ commit }, payload) {
      try {
        const res = await call.moreProvider(payload);
        const { result, msg, data } = res.data;
        payload.data = data;
        commit("updateProviderArray", payload);
        commit("updateData", {
          limit: data.limit,
          msg,
          result,
        });
      } catch (error) {
        console.log("moreProvider", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    providerList({ commit }) {
      commit("providerList");
    },
    resetSearch({ commit }) {
      commit("resetSearch");
    },
  },
};
