import { instance } from "@/api/http";

export default {
  getCartList(payload = {}) {
    return instance({
      url: "/ramm_api/order/get_cart_list",
      method: "post",
      data: payload,
    });
  },
  deleteCart(payload = {}) {
    return instance({
      url: "/ramm_api/order/del_cart",
      method: "post",
      data: payload,
    });
  },
  getOrderView(payload = {}) {
    return instance({
      url: "/ramm_api/order/get_order_view",
      method: "post",
      data: payload,
    });
  },
  orderCancel(payload = {}) {
    return instance({
      url: "/ramm_api/order/order_cancel",
      method: "post",
      data: payload,
    });
  },
  orderRefund(payload = {}) {
    return instance({
      url: "/ramm_api/order/order_refund",
      method: "post",
      data: payload,
    });
  },
  getOrderReturn(payload = {}) {
    return instance({
      url: "/ramm_api/order/get_order_return",
      method: "post",
      data: payload,
    });
  },
  setOrderReturn(payload = {}) {
    return instance({
      url: "/ramm_api/order/set_order_return",
      method: "post",
      data: payload,
    });
  },
  getPaymentList(payload = {}) {
    return instance({
      url: "/ramm_api/order/get_payment_list",
      method: "post",
      data: payload,
    });
  },
  getOptionalChanges(payload = {}) {
    return instance({
      url: "/ramm_api/order/get_optional_changes",
      method: "post",
      data: payload,
    });
  },
  setOptionChange(payload = {}) {
    return instance({
      url: "/ramm_api/order/set_option_change",
      method: "post",
      data: payload,
    });
  },
  setDeclaration(payload = {}) {
    return instance({
      url: "/ramm_api/order/set_declaration",
      method: "post",
      data: payload,
    });
  },
  setSettle(payload = {}) {
    return instance({
      url: `/ramm_api/order/set_add?mode=direct`,
      method: "post",
      data: payload,
    });
  },
  getSettle(payload = {}) {
    return instance({
      url: `/ramm_api/order/get_settle?mode=${payload.mode}`,
      method: "post",
      data: payload,
    });
  },
  orderMemberUpdate(payload = {}) {
    return instance({
      url: "/ramm_api/order/order_member_update",
      method: "post",
      data: payload,
    });
  },
  choiceOne(payload = {}, mode = null) {
    return instance({
      url: `/ramm_api/order/add_settle${mode ? `?mode=${mode}` : ""}`,
      method: "post",
      data: payload,
    });
  },
  payment(payload = {}, mode = null) {
    return instance({
      url: `/ramm_api/order/pay${mode ? `?mode=${mode}` : ""}`,
      method: "post",
      data: payload,
    });
  },
  orderMail(payload = {}, mode = null) {
    return instance({
      url: `/ramm_api/order/order_mail`,
      method: "post",
      data: payload,
    });
  },
  applyCashReceipt(payload = {}, mode = null) {
    return instance({
      url: `/ramm_api/order/cashreceiptwrite`,
      method: "post",
      data: payload,
    });
  },
  viewCashReceipt(payload = {}, mode = null) {
    return instance({
      url: `/ramm_api/order/order_pg_info`,
      method: "post",
      data: payload,
    });
  },
  calculateShippingCost(payload = {}, mode = null) {
    return instance({
      url: `/ramm_api/order/calculate_shipping_cost`,
      method: "post",
      data: payload,
    });
  },
};
