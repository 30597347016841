import { rank as call } from "@/api/call";

export default {
  namespaced: true,
  state: () => ({
    result: false,
    msg: null,
    listArray: [],
    limit: 0,
    totalPage: 0,
    detail: [],
    category: [],
  }),
  getters: {},
  mutations: {
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key === "listArray") {
          state[key] = [...state[key], ...payload[key]];
        } else {
          state[key] = payload[key];
        }
      });
    },
    dataClear(state) {
      state.result = false;
      state.msg = null;
      state.listArray = [];
      state.limit = 0;
      state.totalPage = 0;
      state.detail = [];
      state.category = [];
    },
    resetState(state) {
      state.listArray = [];
      state.limit = 0;
      state.totalPage = 0;
      state.detail = [];
    },
    resetDetail(state) {
      state.detail = [];
    },
  },
  actions: {
    async getRammRank({ commit, state }, payload) {
      try {
        const res = await call.getRammRank(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          if (payload.reset) {
            commit("resetState");
          }
          if (payload.category_code) {
            commit("updateData", {
              listArray: data.list,
              limit: data.limit,
              totalPage: data.total_page,
              msg,
              result,
            });
          } else {
            commit("updateData", {
              listArray: data.list,
              limit: data.limit,
              totalPage: data.total_page,
              category: data.category,
              msg,
              result,
            });
          }
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getRammRank", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getRankDetail({ commit }, payload) {
      commit("resetDetail");
      try {
        const res = await call.getRankDetail(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          detail: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getRankDetail", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    dataClear({ commit }) {
      commit("dataClear");
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
};
