export default [
  {
    name: "Write",
    path: "/review/write",
    component: () => import("@/views/review/Write"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewSelectCategory",
    path: "/review/review_select_category",
    component: () => import("@/views/review/ReviewSelectCategory"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewChoice",
    path: "/review/review_choice",
    component: () => import("@/views/review/ReviewChoice"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewCurrentState",
    path: "/review/review_current_state",
    component: () => import("@/views/review/ReviewCurrentState"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewStatistics",
    path: "/review/review_statistics",
    component: () => import("@/views/review/ReviewStatistics"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewNewsWriteCate1",
    path: "/review/review_news_write/:category1",
    component: () => import("@/views/review/ReviewNewsWrite"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewNewsWriteCate2",
    path: "/review/review_news_write/:category1/:category2",
    component: () => import("@/views/review/ReviewNewsWrite"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewNormalGoodsChoice",
    path: "/review/review_normal_goods_choice/:category/:goodsSeq",
    component: () => import("@/views/review/ReviewNormalGoodsChoice"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewNoramlGoodsWrite",
    path: "/review/review_normal_goods_write/:category/:goodsSeq",
    component: () => import("@/views/review/ReviewNoramlGoodsWrite"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewProGoodsChoice",
    path: "/review/review_pro_goods_choice",
    component: () => import("@/views/review/ReviewProGoodsChoice"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewProGoodsWrite",
    path: "/review/review_pro_goods_write",
    component: () => import("@/views/review/ReviewProGoodsWrite"),
    meta: {
      auth: true,
      isReviewer: true,
    },
  },
  {
    name: "ReviewSellerGoodsChoice",
    path: "/review/review_seller_goods_choice/:category/:goodsSeq",
    component: () => import("@/views/review/ReviewSellerGoodsChoice"),
    meta: {
      auth: true,
      isSeller: true,
    },
  },
  {
    name: "ReviewSellerGoodsWrite",
    path: "/review/review_seller_goods_write/:category/:goodsSeq",
    component: () => import("@/views/review/ReviewSellerGoodsWrite"),
    meta: {
      auth: true,
      isSeller: true,
    },
  },
  {
    name: "ReviewMain",
    path: "/review",
    redirect: "/review/news_main",
    component: () => import("@/views/review/ReviewMain"),
    children: [
      {
        name: "ReviewGoods",
        path: "goods_main",
        component: () => import("@/components/review/menu/GoodsMain"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewGoodsDetailLevel2Cate",
        path: "goods_main/:category",
        component: () => import("@/components/review/menu/GoodsMain"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewGoodsDetailLevel2CateCategoty",
        path: "goods_main/:level2Cate/:category",
        component: () => import("@/components/review/menu/GoodsMain"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewGoodsDetailLevel2CateCategotyGoodsSeq",
        path: "goods_main/:level2Cate/:category/:goodsSeq",
        component: () => import("@/components/review/menu/GoodsMain"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewSeller",
        path: "seller_main",
        component: () => import("@/components/review/menu/SellerMain"),
        meta: {
          loginState: true,
          isSeller: true,
        },
      },
      {
        name: "ReviewSellerDetailLevel2Cate",
        path: "seller_main/:category",
        component: () => import("@/components/review/menu/SellerMain"),
        meta: {
          loginState: true,
          isSeller: true,
        },
      },
      {
        name: "ReviewSellerDetailLevel2CateCategoty",
        path: "seller_main/:level2Cate/:category",
        component: () => import("@/components/review/menu/SellerMain"),
        meta: {
          loginState: true,
          isSeller: true,
        },
      },
      {
        name: "ReviewerGoodsMenu",
        path: "reviewer_goods/:memberId",
        component: () => import("@/components/review/menu/ReviewerGoods"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewerGoodsDetailLevel2Cate",
        path: "reviewer_goods/:memberId/:cateId",
        component: () => import("@/components/review/menu/ReviewerGoods"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewNewsMainList",
        path: "news_main",
        component: () => import("@/components/review/menu/NewsMain"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewNewsMainListMainMenu",
        path: "news_main/:menu",
        component: () => import("@/components/review/menu/NewsMain"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewerNews",
        path: "reviewer_news/:memberId",
        component: () => import("@/components/review/menu/ReviewerNews"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewerNewsListMainMenu",
        path: "reviewer_news/:memberId/:cateId",
        component: () => import("@/components/review/menu/ReviewerNews"),
        meta: {
          loginState: true,
        },
      },
      {
        name: "ReviewDumy",
        path: "ready",
        component: () => import("@/components/review/menu/DumyMain"),
      },
    ],
  },
  {
    name: "ReviewGoodsCategoryList",
    path: "/review_goods_category_list",
    component: () => import("@/views/review/ReviewGoodsCategoryList"),
    meta: {
      main: true,
    },
  },
  {
    name: "ReviewGoodsCategoryListCate3",
    path: "/review_goods_category_list/:open",
    component: () => import("@/views/review/ReviewGoodsCategoryList"),
    meta: {
      main: true,
    },
  },
];
