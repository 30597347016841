import {
  mypage as call,
  member as memberCall,
  channel as chCall,
} from "@/api/call";
import jScommon from "@/functions/jScommon";

export default {
  namespaced: true,
  state: () => ({
    password: null,
    result: null,
    msg: null,
    myInfo: {},
    isMailAuth: false,
    authNumber: null,
    isNickname: false,
    checkAuth: false,
    totalPoint: null,
    useablePoint: null,
    passbookImg: null,
    limit: 0,
    listArray: [],
    reviewPoint: 0,
    userPoint: 0,
    sellerApplyInfo: [],
    shippingAddress: [],
    totalPage: 0,
    channel: {
      normal: true,
      reviewer: false,
      seller: false,
      exchange: false,
    },
    buyConfirm: [],
    isPopup: false,
    completeOrder: null,
    reviewerReceipt: null,
    shippingReceipt: null,
  }),
  getters: {},
  mutations: {
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key === "listArray") {
          // 만약 리스트 만 업데이트 했을 경우
          state[key] = [...state[key], ...payload[key]];
        } else {
          if (key === "business_doc_file") {
            //만약 사업자 등록증 업데이트 일 경우
            state.sellerApplyInfo["business_doc_file"] = payload[key];
          }
          state[key] = payload[key];
        }
      });
    },
    updateMyInfo(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.myInfo[key] = payload[key];
      });
    },
    updateChannel(state, payload) {
      state.myInfo.channel = payload; //세션스토리지에 있는 채널값 세팅
      Object.keys(state.channel).forEach((key) => {
        state.channel[key] = false;
        if (key === payload) {
          state.channel[key] = true;
        }
      });
    },
    updateWish(state, { review_seq, wish_yn }) {
      state.listArray.map((list) => {
        if (list.review_seq === review_seq) {
          return (list.wish_yn = wish_yn);
        }
      });
    },
    updateReply(state, { seq, content }) {
      state.listArray.map((list) => {
        if (list.seq === seq) {
          return (list.content = content.replace(/(?:\r\n|\r|\n)/g, "<br/>"));
        }
      });
    },
    deleteReply(state, { seq }) {
      const index = state.listArray.findIndex((list) => list.seq === seq);
      state.listArray.splice(index, 1);
    },
    listReset(state) {
      state.listArray = [];
    },
    clearMyInfo(state) {
      state.password = null;
      state.authNumber = null;
      state.password = null;
      state.result = null;
      state.checkAuth = false;
      state.msg = null;
      state.myInfo = {};
      state.isNickname = false;
      state.isMailAuth = false;
      state.totalPoint = null;
      state.useablePoint = null;
      state.passbookImg = null;
      state.limit = 0;
      state.listArray = [];
      state.reviewPoint = 0;
      state.userPoint = 0;
      state.sellerApplyInfo = [];
      state.shippingAddress = [];
      state.totalPage = 0;
      state.channel = {
        normal: true,
        reviewer: false,
        seller: false,
        exchange: false,
      };
      state.isPopup = false;
      state.completeOrder = null;
      state.reviewerReceipt = null;
      state.shippingReceipt = null;
    },
    clearAuth(state) {
      state.authNumber = null;
      state.isNickname = false;
      state.isMailAuth = false;
    },
    clearRammPoint(state) {
      state.totalPoint = null;
      state.listArray = [];
    },
    updateWishComment(state, payload) {
      console.log(payload, state.listArray);
      state.listArray.map((list, index) => {
        console.log(state.listArray[index].member_seq, payload.reviewer_seq);
        if (parseInt(list.member_seq) === parseInt(payload.reviewer_seq)) {
          console.log(state.listArray[index].member_seq, payload.reviewer_seq);
          state.listArray[index].wish_yn = payload.wish_yn;
        }
      });
    },
  },
  actions: {
    async authCheck({ commit }, payload) {
      try {
        const setData = {
          password: payload,
        };
        const res = await memberCall.authCheck(setData);
        const { result, msg } = res.data; //데이터 구조 분해

        commit("updateData", {
          msg,
          result,
          checkAuth: result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getMypageMyinfo({ commit }) {
      try {
        const res = await call.getMypageMyinfo();
        const { result, msg, data } = res.data; //데이터 구조 분해
        commit("updateData", {
          myInfo: data,
          msg,
          result,
        });
        commit("updateChannel", sessionStorage.getItem("channel") || "normal");
      } catch (error) {
        console.log("getMypageMyinfo", error);
        commit("updateData", {
          myInfo: [],
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setUserImage({ commit }, payload) {
      try {
        const res = await memberCall.setUserImage(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          const image = jScommon.setTypeImage(data);
          commit("updateMyInfo", {
            ...image,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deleteUserImage({ commit }, payload) {
      try {
        const setData = {
          ...payload,
        };
        const res = await memberCall.deleteUserImage(setData);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          const image = jScommon.setTypeImage(data);
          commit("updateMyInfo", {
            ...image,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async dupleNickname({ commit }, payload) {
      try {
        const res = await memberCall.dupleNickname(payload);
        const { result, msg } = res.data; //데이터 구조 분해
        commit("updateData", {
          msg,
          result,
          isNickname: result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
          isNickname: false,
        });
      }
    },
    async changeNickname({ commit }, payload) {
      try {
        const res = await memberCall.changeNickname(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          const nickname = jScommon.setTypeNickname(data);
          commit("updateMyInfo", {
            ...nickname,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async changePhoneNumber({ commit }, payload) {
      try {
        const res = await memberCall.changePhoneNumber(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          commit("updateMyInfo", {
            cellphone: data.cellphone,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async authEmail({ commit }, payload) {
      try {
        const res = await memberCall.authEmail(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          commit("updateData", {
            msg,
            result,
            authNumber: data.auth_number,
            isMailAuth: true,
          });
        } else {
          commit("updateData", {
            msg,
            result,
            isMailAuth: false,
            authNumber: null,
          });
        }
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async authEmailComfirm({ state, commit }, payload) {
      try {
        const setData = {
          ...payload,
          auth_number: state.authNumber,
        };
        const res = await memberCall.authEmailComfirm(setData);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          commit("updateMyInfo", {
            email: data.email,
          });
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async chagePassword({ commit }, payload) {
      try {
        const res = await memberCall.chagePassword(payload);
        const { result, msg } = res.data; //데이터 구조 분해
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async consentSmsMail({ commit }, payload) {
      try {
        const res = await memberCall.consentSmsMail(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          if (data.type === "sms") {
            commit("updateMyInfo", {
              sms: data.value,
            });
          } else {
            commit("updateMyInfo", {
              mailing: data.value,
            });
          }
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async channelChange({ commit }, payload) {
      try {
        sessionStorage.removeItem("channel");
        const res = await chCall.channelChange(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          sessionStorage.setItem("channel", data.channel);
          commit("updateChannel", data.channel);
          commit("updateData", {
            msg,
            result,
            myInfo: data,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async reviewerRegister({ commit }, payload) {
      try {
        const res = await chCall.reviewerRegister(payload);
        const { result, msg, data } = res.data;
        if (result) {
          // commit("updateChannel", data.channel);
          commit("updateData", {
            msg,
            result,
            completeOrder: data,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("myinfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async sellerApply({ commit }, payload) {
      try {
        const res = await chCall.sellerApply(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("seller register ", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getRammPoint({ commit }, { page, sort, reset }) {
      try {
        const res = await call.getRammPoint({ page, sort });
        const { result, msg, data } = res.data;
        if (result) {
          if (reset) {
            commit("listReset");
          }
          commit("updateData", {
            msg,
            result,
            totalPoint: data.total_point,
            listArray: data.point_list,
            totalPage: data.total_page,
            limit: data.limit,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("get my point error ", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deliveryAddress({ commit }, payload) {
      try {
        const res = await call.deliveryAddress(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("delivery address", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getShippingAddress({ commit }, payload) {
      try {
        const res = await call.getShippingAddress(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          msg,
          result,
          shippingAddress: data,
        });
      } catch (error) {
        console.log("shipping address", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getShippingList({ commit }, payload) {
      try {
        const res = await call.getShippingList(payload);
        const { result, msg, data } = res.data;
        commit("listReset");
        commit("updateData", {
          msg,
          result,
          listArray: data,
        });
      } catch (error) {
        console.log("shipping list", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deleteShipping({ commit }, payload) {
      try {
        const res = await call.deleteShipping(payload);
        const { result, msg, data } = res.data;
        commit("listReset");
        commit("updateData", {
          msg,
          result,
          listArray: data,
        });
      } catch (error) {
        console.log("shipping delete", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getCommentList({ commit }, { page, sort, reset }) {
      try {
        const res = await call.getCommentList({ page, sort });
        const { result, msg, data } = res.data;
        if (result) {
          if (reset) {
            commit("listReset");
          }
          commit("updateData", {
            msg,
            result,
            listArray: data.reply_list,
            totalPage: data.total_page,
            limit: data.limit,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("shipping delete", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async updateWish({ commit }, payload) {
      try {
        const res = await call.updateWish(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateWish", payload);
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("updateWish", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async updateReply({ commit }, payload) {
      try {
        const res = await call.updateReply(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateReply", payload);
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("updateReply", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deleteRely({ commit }, payload) {
      try {
        const res = await call.deleteRely(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("deleteReply", payload);
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("deleteRely", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReviewPointInfo({ commit }) {
      commit("updateData", {
        totalPoint: null,
        useablePoint: null,
        passbookImg: null,
      });
      try {
        const res = await call.getReviewPointInfo();
        const { result, msg, data } = res.data;
        commit("updateData", {
          msg,
          result,
          totalPoint: data.point.review_point,
          useablePoint: data.point.point,
          passbookImg: data.passbook_img,
        });
      } catch (error) {
        console.log("deleteRely", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async submitApply({ state, commit }, payload) {
      try {
        const res = await call.submitApply(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateData", {
            useablePoint:
              parseInt(state.useablePoint) - parseInt(payload.point),
          }); //신청 패이지에 있는 신청가능 포인트 변경
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("submitApply", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async submitPassBookImg({ commit }, payload) {
      try {
        const res = await call.submitPassBookImg(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            passbookImg: data.passbookImg,
          }); //신청 패이지에 있는 통장사본 이미지 변경
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("submitPassBookImg", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getReviewEvalutation({ commit }, { page, sort, reset, type }) {
      try {
        const res = await call.getReviewEvalutation({ page, sort, type });
        const { result, msg, data } = res.data;
        if (result) {
          if (reset) {
            commit("listReset");
          }
          commit("updateData", {
            msg,
            result,
            listArray: data.list,
            totalPage: data.total_page,
            limit: data.limit,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getReviewEvalutation", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async changeMainCategory({ commit }, payload) {
      try {
        const res = await call.changeMainCategory(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("changeMainCategory", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getSellerApplyStatus({ commit }) {
      try {
        const res = await call.getSellerApplyStatus();
        const { result, msg, data } = res.data;
        commit("updateData", {
          sellerApplyInfo: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getSellerApplyStatus", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async docReUpload({ commit }, payload) {
      try {
        const res = await call.docReUpload(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          msg,
          result,
          business_doc_file: data.providerImg,
        });
      } catch (error) {
        console.log("docReUpload", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getRewardRammPoint({ commit }, { page, sort, reset }) {
      try {
        const res = await call.getRewardRammPoint({ page, sort });
        const { result, msg, data } = res.data;
        if (result) {
          if (reset) {
            commit("listReset");
          }
          commit("updateData", {
            msg,
            result,
            reviewPoint: data.review_point, // 정산 전 포인트
            userPoint: data.user_point, // 인출 가능 포인트
            listArray: data.result,
            totalPage: data.total_page,
            limit: data.limit,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getRewardRammPoint", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getAlarmData({ commit }, { page, reset, keyword }) {
      try {
        if (reset) {
          commit("listReset");
        }
        const res = await call.getAlarmData({ page, keyword });
        const { result, msg, data } = res.data;
        commit("updateData", {
          listArray: data.list,
          result,
          msg,
          totalPage: data.total_page,
          limit: data.limit,
        });
      } catch (error) {
        console.log("getAlarmData", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setMoveLink({ commit }, payload) {
      try {
        const res = await call.setMoveLink(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          result,
          msg,
        });
        commit(
          "member/updateUserInfo",
          { alarmCnt: data.alarm_cnt },
          { root: true }
        );
        console.log(data.alarm_cnt);
      } catch (error) {
        console.log("setMoveLink", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async reportSet({ commit }, payload) {
      try {
        const res = await call.reportSet(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("reportSet", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async buyConfirim({ commit }, payload) {
      try {
        const res = await call.buyConfirim(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          buyConfirm: data,
          result,
          msg,
        });
      } catch (error) {
        console.log("buyConfirim", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getMySaleList({ commit }, payload) {
      try {
        const res = await call.getMySaleList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("listReset");
          }
          commit("updateData", {
            listArray: data.list,
            totalPage: data.total_page,
            limit: data.limit,
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("getMySaleList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setChangePoint({ commit }, payload) {
      try {
        const res = await call.setChangePoint(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("setChangePoint", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async ReviewerReceipt({ commit }) {
      try {
        const res = await call.ReviewerReceipt();
        const { result, msg, data } = res.data;
        commit("updateData", {
          result,
          msg,
          reviewerReceipt: data,
        });
      } catch (error) {
        console.log("ReviewerReceipt", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async shippingReceipt({ commit }, paylaod) {
      try {
        commit("updateData", { shippingReceipt: null });
        const res = await call.shippingReceipt(paylaod);
        const { result, msg, data } = res.data;
        commit("updateData", {
          result,
          msg,
          shippingReceipt: data,
        });
      } catch (error) {
        console.log("shippingReceipt", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    clearAuth({ commit }) {
      commit("clearAuth");
    },
    clearRammPoint({ commit }) {
      commit("clearRammPoint");
    },
    clearRammList({ commit }) {
      commit("listReset");
    },
    updateWishComment({ commit }, payload) {
      commit("updateWishComment", payload);
    },
  },
};
