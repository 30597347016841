import { instance } from "@/api/http";

export default {
  getMypageMyinfo(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/get_mypage_myinfo",
      method: "post",
      data: payload,
    });
  },
  deliveryAddress(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/delivery_address",
      method: "post",
      data: payload,
    });
  },
  getShippingAddress(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/my_shipping_address",
      method: "post",
      data: payload,
    });
  },
  getShippingList(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/my_delivery_address",
      method: "post",
      data: payload,
    });
  },
  deleteShipping(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/my_delivery_delete",
      method: "post",
      data: payload,
    });
  },
  getCommentList(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/comment_list",
      method: "post",
      data: payload,
    });
  },
  updateWish(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/update_wish",
      method: "post",
      data: payload,
    });
  },
  updateReply(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/update_reply",
      method: "post",
      data: payload,
    });
  },
  deleteRely(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/delete_reply",
      method: "post",
      data: payload,
    });
  },
  getReviewPointInfo(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/get_review_point_info",
      method: "post",
      data: payload,
    });
  },
  submitApply(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/ramm_emoney_apply",
      method: "post",
      data: payload,
    });
  },
  submitPassBookImg(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/submit_pass_bookImg",
      method: "post",
      data: payload,
    });
  },
  getReviewEvalutation(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/get_review_evalutation",
      method: "post",
      data: payload,
    });
  },
  changeMainCategory(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/change_main_category",
      method: "post",
      data: payload,
    });
  },
  getSellerApplyStatus(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/get_seller_apply_status",
      method: "post",
      data: payload,
    });
  },
  docReUpload(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/doc_re_upload",
      method: "post",
      data: payload,
    });
  },
  getRewardRammPoint(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/get_reward_ramm_point",
      method: "post",
      data: payload,
    });
  },
  getRammPoint(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/get_ramm_point",
      method: "post",
      data: payload,
    });
  },
  getAlarmData(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/ramm_alarm",
      method: "post",
      data: payload,
    });
  },
  setMoveLink(payload = {}) {
    return instance({
      url: "/ramm_api/mypage/set_move_link",
      method: "post",
      data: payload,
    });
  },
  reportSet(payload = {}) {
    return instance({
      url: `/ramm_api/mypage/set_report`,
      method: "post",
      data: payload,
    });
  },
  buyConfirim(payload = {}) {
    return instance({
      url: `/ramm_api/mypage/buy_confirm`,
      method: "post",
      data: payload,
    });
  },
  getMySaleList(payload = {}) {
    return instance({
      url: `/ramm_api/mypage/get_my_sale_list`,
      method: "post",
      data: payload,
    });
  },
  setChangePoint(payload = {}) {
    return instance({
      url: `/ramm_api/mypage/set_change_point`,
      method: "post",
      data: payload,
    });
  },
  ReviewerReceipt(payload = {}) {
    return instance({
      url: `/ramm_api/mypage/get_reviewer_receipt`,
      method: "post",
      data: payload,
    });
  },
  shippingReceipt(payload = {}) {
    return instance({
      url: `/ramm_api/mypage/get_return_receipt`,
      method: "post",
      data: payload,
    });
  },
};
