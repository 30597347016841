import { wish as call } from "@/api/call";

export default {
  namespaced: true,
  state: () => ({
    result: false,
    msg: null,
    mainMenuList: [],
    listArray: [],
    limit: 0,
    totalPage: 0,
  }),
  getters: {},
  mutations: {
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key === "listArray") {
          state[key] = [...state[key], ...payload[key]];
        } else {
          state[key] = payload[key];
        }
      });
    },
    updateWish(state, payload) {
      let index = 0;
      index = state.listArray.findIndex((list) => {
        if (payload.type === "reviewer") {
          return list.reviewer_seq === payload.reviewer_seq;
        } else {
          return list.review_seq === payload.review_seq;
        }
      });
      state.listArray.splice(index, 1);
    },
    updateWishAlarm(state, { reviewer_seq, alarm_yn }) {
      state.listArray.map((list) => {
        if (list.reviewer_seq === reviewer_seq) {
          return (list.alarm_yn = alarm_yn);
        }
      });
    },
    updateWishShop(state, { reviewer_seq }) {
      let index = 0;
      index = state.listArray.findIndex(
        (list) => list.reviewer_seq === reviewer_seq
      );
      //console.log(index);
      state.listArray.splice(index, 1);
    },
    dataClear(state) {
      state.result = false;
      state.msg = null;
      state.mainMenuList = [];
      state.listArray = [];
      state.limit = 0;
      state.totalPage = 0;
    },
    resetState(state) {
      state.listArray = [];
      state.limit = 0;
      state.totalPage = 0;
    },
  },
  actions: {
    async getWishMenu({ commit }) {
      commit("dataClear");
      try {
        const res = await call.getWishMenu();
        const { result, msg, data } = res.data; //데이터 구조 분해
        commit("updateData", {
          mainMenuList: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getWishMenu", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getWishData({ commit }, payload) {
      try {
        const res = await call.getWishData(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          if (payload.reset) {
            commit("resetState");
          }
          commit("updateData", {
            listArray: data.list,
            limit: data.limit,
            totalPage: data.total_page,
            msg,
            result,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getWishData", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async updateWish({ commit }, payload) {
      try {
        const res = await call.updateWish(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateWish", payload);
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("updateWish", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async updateWishAlarm({ commit }, payload) {
      try {
        const res = await call.updateWishAlarm(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateWishAlarm", payload);
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("updateWishAlarm", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async updateWishShop({ commit }, payload) {
      try {
        const res = await call.updateWishShop(payload);
        const { result, msg } = res.data;
        if (result) {
          if (!payload.is_shop) {
            commit("updateWishShop", payload);
          }
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("updateWishShop", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async updateWishShopAlarm({ commit }, payload) {
      try {
        const res = await call.updateWishShopAlarm(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateWishAlarm", payload);
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("updateWishShopAlarm", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    dataClear({ commit }) {
      commit("dataClear");
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
};
