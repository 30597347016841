<template>
  <div>
    <MobFloatVue />
    <div id="footer" v-if="viewFooter">
      <div class="footer_move">
        <router-link to="" @click.native="toTop">TOP ▲</router-link>
      </div>
      <div class="footer_top">
        <ul class="footer_top_1">
          <!-- <li><router-link to="#">리뷰 거래소 가기</router-link></li> -->
          <li>
            <router-link to="/introduce/business"
              >Review N MakeMoney와 사업하기</router-link
            >
          </li>
          <li>
            <router-link to="" @click.native="open('menual')">
              램가게 입점하기
            </router-link>
          </li>
        </ul>
        <ul class="footer_top_2">
          <li>
            <router-link to="" @click.native="open('service')"
              >이용약관</router-link
            >
          </li>
          <li>
            <router-link
              to=""
              @click.native="open('privacy')"
              style="font-weight: 600"
              >개인정보처리방침</router-link
            >
          </li>
          <li>
            <router-link to="/cs/notice">고객센터</router-link>
          </li>
          <li><router-link to="/introduce/info">회사소개</router-link></li>
        </ul>
      </div>
      <div class="footer_bottom">
        <p>상호&nbsp;&nbsp;<b>주식회사 리뷰앤메이크머니</b></p>
        <p>사업자등록번호&nbsp;&nbsp;<b>548-86-01830</b></p>
        <p>통신판매업신고번호&nbsp;&nbsp;<b>제 2022-서울성동-01035호</b></p>
        <p>
          대표이사&nbsp;&nbsp;<b>이상호</b>&nbsp;&nbsp;<b>|</b>&nbsp;&nbsp;이메일
          <b>info@reviewnmakemoney.com</b>
        </p>
        <p>
          주소&nbsp;&nbsp;<b>서울특별시 성동구 아차산로 110, 성광빌딩 302호</b>
        </p>
        <p>대표전화&nbsp;&nbsp;<b>070-4284-5559</b></p>
      </div>
    </div>
    <div id="footer_fixed" v-if="viewFooter">
      <div class="box">
        <router-link
          to="/reviewer_rank"
          :class="{ 'router-link-exact-active': mypage, active: rank }"
          ><img src="@/assets/images/m_footer_ico_01.png" alt="" />
          <p>리뷰어순위</p></router-link
        >
      </div>
      <div class="box">
        <router-link
          to="/wish"
          :class="{ 'router-link-exact-active': mypage, active: wish }"
          ><img src="@/assets/images/m_footer_ico_02.png" alt="" />
          <p>찜</p></router-link
        >
      </div>
      <div class="box">
        <router-link to="/" active-class="active"
          ><img src="@/assets/images/m_footer_ico_03.png" alt="" />
          <p>홈</p></router-link
        >
      </div>
      <div class="box" style="position: relative">
        <div class="new_alarm" v-if="parseInt(alarmCnt) > 0">N</div>
        <router-link
          to="/alarm"
          :class="{ 'router-link-exact-active': mypage, active: alarm }"
          ><img src="@/assets/images/m_footer_ico_04.png" alt="" />
          <p>메시지/알림</p></router-link
        >
      </div>
      <div class="box">
        <router-link
          to="/mypage/my_info"
          :class="{ 'router-link-exact-active': mypage, active: mypage }"
          ><img src="@/assets/images/m_footer_ico_05.png" alt="" />
          <p>마이페이지</p></router-link
        >
      </div>
    </div>
    <Service class="modals" v-if="isService" :isModals="true" @open="open" />
    <Privacy class="modals" v-if="isPrivacy" :isModals="true" @open="open" />
    <ProviderMenual
      class="modals"
      v-if="isMenual"
      :isModals="true"
      @open="open"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Service from "@/views/policy/Service.vue";
import Privacy from "@/views/policy/Privacy.vue";
import MobFloatVue from "@/components/ad/MobFloat.vue";
import ProviderMenual from "@/views/footer/ProviderMenual.vue";
export default {
  data() {
    return {
      isService: false,
      isPrivacy: false,
      isMenual: false,
    };
  },
  computed: {
    ...mapState("common", ["viewFooter", "rank", "wish", "alarm", "mypage"]),
    ...mapState("member", ["alarmCnt"]),
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    open(type) {
      document.querySelector("html").style.overflow = "hidden";
      switch (type) {
        case "service":
          this.isMenual = false;
          this.isPrivacy = false;
          this.isService = true;
          break;
        case "privacy":
          this.isMenual = false;
          this.isService = false;
          this.isPrivacy = true;
          break;
        case "menual":
          this.isService = false;
          this.isPrivacy = false;
          this.isMenual = true;
          break;
        default:
          document.querySelector("html").style.overflow = "";
          this.isMenual = false;
          this.isPrivacy = false;
          this.isService = false;
          break;
      }
      console.log(document.querySelector("html").style.overflow);
    },
  },
  components: {
    MobFloatVue,
    Service,
    Privacy,
    ProviderMenual,
  },
};
</script>

<style lang="scss" scoped>
.router-link-exact-active {
  &.active {
    background-color: $primary-color;
  }
}
.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  overflow: scroll;
  height: calc(100% - 40px);
}
.new_alarm {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 8px;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 50%;
  color: white;
  margin-left: 8px;
  margin-top: 3px;
  line-height: 15px;
  text-align: center;
}
</style>
