import { instance } from "@/api/http";

export default {
  getRammRank(payload = {}) {
    return instance({
      url: "/ramm_api/rank/get_ramm_rank",
      method: "post",
      data: payload,
    });
  },
  getRankDetail(payload = {}) {
    return instance({
      url: "/ramm_api/rank/get_rank_detail",
      method: "post",
      data: payload,
    });
  },
};
