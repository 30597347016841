import { cs as call } from "@/api/call";

export default {
  namespaced: true,
  state: () => ({
    result: false,
    msg: null,
    listArray: [],
    noticeArray: [],
    limit: 0,
    totalPage: 0,
  }),
  getters: {},
  mutations: {
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key === "listArray") {
          state[key] = [...state[key], ...payload[key]];
        } else {
          state[key] = payload[key];
        }
      });
    },
    deleteQna(state, { seq }) {
      const idx = state.listArray.findIndex((lilst) => lilst.seq === seq);
      state.listArray.splice(idx, 1);
    },
    modifyQna(state, { seq, content }) {
      state.listArray.map((list) => {
        if (list.seq === seq) {
          return (list.contents = content.replace(
            /(?:\r\n|\r|\n)/g,
            "\r\n<br />"
          ));
        }
      });
    },
    listReset(state) {
      state.listArray = [];
    },
    dataClear(state) {
      state.result = false;
      state.msg = null;
      state.listArray = [];
      state.noticeArray = [];
      state.limit = 0;
      state.totalPage = 0;
    },
    resetState(state) {
      state.listArray = [];
      state.limit = 0;
      state.totalPage = 0;
    },
  },
  actions: {
    async getList({ commit }, payload) {
      try {
        const res = await call.getList(payload);
        const { result, msg, data } = res.data; //데이터 구조 분해
        if (result) {
          if (payload.reset) {
            commit("listReset");
          }
          commit("updateData", {
            listArray: data.loop,
            noticeArray: data.notice,
            totalPage: data.total_page,
            limit: data.limit,
            msg,
            result,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async qnaWrite({ commit }, payload) {
      try {
        const res = await call.qnaProcess(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("qnaWrite", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async modifyQna({ commit }, payload) {
      try {
        const res = await call.qnaProcess(payload.formData);
        const { result, msg, data } = res.data;
        if (result) {
          commit("modifyQna", payload);
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("modifyQna", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deleteQna({ commit }, payload) {
      try {
        const res = await call.qnaProcess(payload.formData);
        const { result, msg, data } = res.data;
        if (result) {
          commit("deleteQna", payload);
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("deleteQna", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    dataClear({ commit }) {
      commit("dataClear");
    },
    resetState({ commit }) {
      commit("resetState");
    },
  },
};
