import { instance } from "@/api/http";

export default {
  getList(payload = {}) {
    return instance({
      url: `/ramm_api/board/board?id=${payload.id}`,
      method: "post",
      data: payload,
    });
  },
  qnaProcess(payload = {}) {
    return instance({
      url: `/ramm_api/board_process`,
      method: "post",
      data: payload,
    });
  },
};
