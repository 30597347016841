import { instance } from "@/api/http";

export default {
  loginCheck(payload = {}) {
    return instance({
      url: "/ramm_api/member/login_check",
      method: "post",
      data: payload,
    });
  },
  login(payload = {}) {
    return instance({
      url: "/ramm_api/member/login",
      method: "post",
      data: payload,
    });
  },
  authPhone(payload = {}) {
    return instance({
      url: "/ramm_api/member/auth_phone",
      method: "post",
      data: payload,
    });
  },
  authPhoneConfirm(payload = {}) {
    return instance({
      url: "/ramm_api/member/auth_phone_confirm",
      method: "post",
      data: payload,
    });
  },
  registerOk(payload = {}) {
    return instance({
      url: "/ramm_api/member/register_ok",
      method: "post",
      data: payload,
    });
  },
  authCheck(payload = {}) {
    return instance({
      url: "/ramm_api/member/auth_check",
      method: "post",
      data: payload,
    });
  },
  setUserImage(payload = {}) {
    return instance({
      url: "/ramm_api/member/modifiy_member_img",
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "nultipart/form-data",
      },
    });
  },
  deleteUserImage(payload = {}) {
    return instance({
      url: "/ramm_api/member/delete_member_img",
      method: "post",
      data: payload,
    });
  },
  dupleNickname(payload = {}) {
    return instance({
      url: "/ramm_api/member/nickname_chk",
      method: "post",
      data: payload,
    });
  },
  changeNickname(payload = {}) {
    return instance({
      url: "/ramm_api/member/change_nickname",
      method: "post",
      data: payload,
    });
  },
  changePhoneNumber(payload = {}) {
    return instance({
      url: "/ramm_api/member/change_phone_number",
      method: "post",
      data: payload,
    });
  },
  authEmail(payload = {}) {
    return instance({
      url: "/ramm_api/member/auth_email",
      method: "post",
      data: payload,
    });
  },
  authEmailComfirm(payload = {}) {
    return instance({
      url: "/ramm_api/member/auth_email_comfirm",
      method: "post",
      data: payload,
    });
  },
  chagePassword(payload = {}) {
    return instance({
      url: "/ramm_api/member/chage_password",
      method: "post",
      data: payload,
    });
  },
  consentSmsMail(payload = {}) {
    return instance({
      url: "/ramm_api/member/consent_smsmail",
      method: "post",
      data: payload,
    });
  },
  loginState(payload = {}) {
    return instance({
      url: "/ramm_api/member/login_state",
      method: "post",
      data: payload,
    });
  },
  userAuthPhone(payload = {}) {
    return instance({
      url: "/ramm_api/member/user_auth_phone",
      method: "post",
      data: payload,
    });
  },
  userAuthPhoneCheck(payload = {}) {
    return instance({
      url: "/ramm_api/member/user_auth_phone_check",
      method: "post",
      data: payload,
    });
  },
  reviewerWithdraw(payload = {}) {
    return instance({
      url: "/ramm_api/member/reviewer_withdraw",
      method: "post",
      data: payload,
    });
  },
  sellerWithdraw(payload = {}) {
    return instance({
      url: "/ramm_api/member/seller_withdraw",
      method: "post",
      data: payload,
    });
  },
  checkSellerWithdraw(payload = {}) {
    return instance({
      url: "/ramm_api/member/check_seller_withdraw",
      method: "post",
      data: payload,
    });
  },
  withdrawal(payload = {}) {
    return instance({
      url: "/ramm_api/member/withdrawal",
      method: "post",
      data: payload,
    });
  },
  getFindAuthNumber(payload = {}) {
    return instance({
      url: "/ramm_api/member/get_find_auth",
      method: "post",
      data: payload,
    });
  },
  authFindPhoneConfirm(payload = {}) {
    return instance({
      url: "/ramm_api/member/auth_find_confirm",
      method: "post",
      data: payload,
    });
  },
};
