export default [
  {
    name: "AuthCheck",
    path: "/mypage/auth_check",
    component: () => import("@/views/mypage/AuthCheck"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfo",
    path: "/mypage/my_info",
    component: () => import("@/views/mypage/MyInfo"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoSetting",
    path: "/mypage/myInfo_setting",
    component: () => import("@/views/mypage/MyInfoSetting"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoRammPointList",
    path: "/mypage/my_ramm_point",
    component: () => import("@/views/mypage/MyInfoRammPointList"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoShippingAddressList",
    path: "/mypage/shipping_address_list",
    component: () => import("@/views/mypage/MyInfoShippingAddressList"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoShippingAddressRegist",
    path: "/mypage/shipping_address_reg",
    component: () => import("@/views/mypage/MyInfoShippingAddressRegist"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoShippingAddressRegistUpdate",
    path: "/mypage/shipping_address_update/:seq",
    component: () => import("@/views/mypage/MyInfoShippingAddressRegist"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoCommentList",
    path: "/mypage/comment_list",
    component: () => import("@/views/mypage/MyInfoCommentList"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoPointWithdrawal",
    path: "/mypage/point_withdrawal",
    component: () => import("@/views/mypage/MyInfoPointWithdrawal"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyInfoPointChange",
    path: "/mypage/point_change",
    component: () => import("@/views/mypage/MyInfoPointChange"),
    meta: {
      auth: true,
    },
  },
  {
    name: "AfterEvaluation",
    path: "/mypage/after_evaluation",
    component: () => import("@/views/mypage/AfterEvaluation"),
    meta: {
      auth: true,
    },
  },
  {
    name: "BeforeEvaluation",
    path: "/mypage/before_evaluation",
    component: () => import("@/views/mypage/BeforeEvaluation"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyCart",
    path: "/mypage/my_cart",
    component: () => import("@/views/mypage/MyCart"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyCartType",
    path: "/mypage/my_cart/:type",
    component: () => import("@/views/mypage/MyCart"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyOrderModify",
    path: "/mypage/order_modify/:no",
    component: () => import("@/views/mypage/MyOrderModify"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyOrderCancel",
    path: "/mypage/order_cancel/:order_seq",
    component: () => import("@/views/mypage/MyOrderCancel"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyOrderDetail",
    path: "/mypage/order_detail/:order_seq",
    component: () => import("@/views/mypage/MyOrderDetail"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyOrderConfirm",
    path: "/mypage/order_confirm/:order_seq/:export_code",
    component: () => import("@/views/mypage/MyOrderConfirm"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyOrderRefund",
    path: "/mypage/order_refund/:order_seq/:item_seq/:option_seq",
    component: () => import("@/views/mypage/MyOrderRefund"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyOrderChange",
    path: "/mypage/order_change/:order_seq/:item_seq/:option_seq",
    component: () => import("@/views/mypage/MyOrderChange"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyPaymentList",
    path: "/mypage/payment_list",
    component: () => import("@/views/mypage/MyPaymentList"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyRewardRammPointList",
    path: "/mypage/reward_ramm_point_list",
    component: () => import("@/views/mypage/MyRewardRammPointList"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyAlarmList",
    path: "/alarm",
    component: () => import("@/views/mypage/MyAlarmList"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MyGoodsSaleList",
    path: "/mypage/my_goods_sale_list",
    component: () => import("@/views/mypage/MyGoodsSaleList"),
    meta: {
      auth: true,
      isSeller: true,
    },
  },
  {
    name: "PolicyService",
    path: "/policy/service",
    component: () => import("@/views/policy/Service"),
  },
  {
    name: "PolicyPrivacy",
    path: "/policy/privacy",
    component: () => import("@/views/policy/Privacy"),
  },
  {
    name: "PolicyMail",
    path: "/policy/mail",
    component: () => import("@/views/policy/Mailing"),
  },
  {
    name: "PolicySms",
    path: "/policy/sms",
    component: () => import("@/views/policy/Sms"),
  },
  {
    name: "MyOrderReceipt",
    path: "/mypage/my_order_receipt/:order_seq",
    component: () => import("@/views/mypage/MyOrderReceipt"),
  },
];
