import { instance } from "@/api/http";

export default {
  getTotalSearch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_total_search",
      method: "post",
      data: payload,
    });
  },
  categorySearch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_category_search",
      method: "post",
      data: payload,
    });
  },
  textCategorySearch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_text_category_search",
      method: "post",
      data: payload,
    });
  },
  textSerch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_text_search",
      method: "post",
      data: payload,
    });
  },
  goodsCategorySearch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_goods_category_search",
      method: "post",
      data: payload,
    });
  },
  reviewerSerch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_reviewer_search",
      method: "post",
      data: payload,
    });
  },
  textGoodsSerch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_text_goods_search",
      method: "post",
      data: payload,
    });
  },
  providerSerch(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_provider_search",
      method: "post",
      data: payload,
    });
  },
  getProviderList(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_provider_list",
      method: "post",
      data: payload,
    });
  },
  moreProvider(payload = {}) {
    return instance({
      url: "/ramm_api/search/get_more_provider",
      method: "post",
      data: payload,
    });
  },
};
