<template>
  <div>
    <h2 class="main_tit">오늘의 상품 리뷰</h2>
    <div class="m_s_review">
      <div class="box" v-for="item in top3List.goods_review" :key="item.seq">
        <div class="top">
          <div class="img">
            <img
              :src="item.thumbnail"
              @error="replaceGoodsImg($event, item.goods_img)"
              alt="상품 리뷰 썸네일"
              @click="
                $router.push(
                  `/review/goods_main/${item.category_code}?goods_seq=${item.goods_seq}&option1=${item.option_category1}&option2=${item.option_category2}&option3=${item.option_category3}&seq=${item.seq}`
                )
              "
            />
          </div>
          <div
            class="txt"
            @click="
              $router.push(
                `/review/goods_main/${item.category_code}?goods_seq=${item.goods_seq}&option1=${item.option_category1}&option2=${item.option_category2}&option3=${item.option_category3}&seq=${item.seq}`
              )
            "
          >
            <h3>
              {{ item.title }}
            </h3>
            <p>상품명 : {{ item.main_goods_name }}</p>
          </div>
        </div>
        <ul class="bottom">
          <li>
            <router-link
              to=""
              @click.native="
                likeCheck({
                  wish_yn: item.wish_yn_reviwer,
                  reviewer_seq: item.member_seq,
                  review_seq: null,
                  main_seq: item.main_menu_seq,
                  type: 'reviewer',
                })
              "
            >
              <img
                v-if="item.wish_yn_reviwer == 'Y'"
                src="@/assets/images/sub/like_on.png"
                alt="" />
              <img
                v-else
                src="@/assets/images/sub/like_off.png"
                alt="" /></router-link
            ><router-link to="">{{ item.nickname }}</router-link>
          </li>
          <li>리뷰어 신뢰도 {{ rammFloat(item.re_trust_score) }}</li>
          <li>제품 평점 {{ rammFloat(item.main_score) }}</li>
        </ul>
      </div>
    </div>
    <h2 class="main_tit">오늘의 뉴스 리뷰</h2>
    <div class="m_s_review">
      <div class="box" v-for="item in top3List.news" :key="item.seq">
        <div class="top">
          <div class="img">
            <img
              :src="item.thumbnail"
              @error="replaceNewsImg"
              alt="뉴스 썸네일"
              @click="
                $router.push(
                  `/review/news_main/${item.option_category1}?category=${item.option_category2}&seq=${item.seq}`
                )
              "
            />
          </div>
          <div
            class="txt"
            @click="
              $router.push(
                `/review/news_main/${item.option_category1}?category=${item.option_category2}&seq=${item.seq}`
              )
            "
          >
            <h3>
              {{ item.title }}
            </h3>
            <p>
              {{ item.option1 }}{{ item.option2 ? `> ${item.option2}` : "" }}
            </p>
          </div>
        </div>
        <ul class="bottom">
          <li>
            <router-link
              to=""
              @click.native="
                likeCheck({
                  wish_yn: item.wish_yn_reviwer,
                  reviewer_seq: item.member_seq,
                  review_seq: null,
                  main_seq: item.main_menu_seq,
                  type: 'reviewer',
                })
              "
            >
              <img
                v-if="item.wish_yn_reviwer == 'Y'"
                src="@/assets/images/sub/like_on.png"
                alt="" />
              <img
                v-else
                src="@/assets/images/sub/like_off.png"
                alt="" /></router-link
            ><router-link to="">{{ item.nickname }}</router-link>
          </li>
          <li>리뷰어 신뢰도 {{ rammFloat(item.re_trust_score) }}</li>
          <li>중요도 {{ rammFloat(item.news_total_score) }}</li>
        </ul>
      </div>
    </div>
    <h2 class="main_tit">
      오늘의 상품<router-link to="/search/provider_search" class="more"
        >램 가게 보러가기 &gt;</router-link
      >
    </h2>
    <div class="m_s_goods">
      <div class="box" v-for="(item, index) in top3List.goods" :key="item.seq">
        <div class="top">
          <div
            class="img"
            @click="
              $router.push(
                `/market_goods_detail/${item.category_id}/${item.goods_seq}`
              )
            "
          >
            <img :src="item.goods_img" alt="" />
          </div>
          <div
            class="txt"
            @click="
              $router.push(
                `/market_goods_detail/${item.category_id}/${item.goods_seq}`
              )
            "
          >
            <h3>{{ item.goods_name }}</h3>
            <p>램 가게 &gt; {{ item.provider_name }}</p>
          </div>
        </div>
        <ul class="bottom">
          <li>가격 : {{ won(item.default_price) }}원</li>
          <!-- <li>배송비 : 3000원</li> -->
          <li>&nbsp;</li>
          <li>
            <router-link to="" @click.native="movePage(index)"
              >리뷰보기</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    this.getNewsList();
  },
  computed: {
    ...mapState("review", ["result", "msg", "top3List"]),
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    replaceNewsImg(e) {
      e.target.src = require("@/assets/images/newsNoimg.png");
    },
    replaceGoodsImg(e, goods_img) {
      if (goods_img) {
        e.target.src = goods_img;
      } else {
        e.target.src = require("@/assets/images/newsNoimg.png");
      }
    },
    movePage(index) {
      const data = this.top3List.goods[index];
      const url = `/review/goods_main/${data.category_code}?goods_seq=${data.goods_seq}&option1=1&option2=0&option3=0&seq=0`;
      this.$router.push(url);
    },
    won(str) {
      return this.$won(str);
    },
    needsLogin() {
      if (!this.$store.state.member.isLogin) {
        this.$confirm("로그인이 필요합니다.")
          .then(() => {
            this.$store.dispatch("common/setReferer", {
              path: this.isOpen
                ? this.nowPage.replace(this.domain, "") +
                  "&seq=" +
                  this.item.seq
                : this.$route.path,
            });
            this.$router.push("/member/login");
            return false;
          })
          .catch(() => {
            return false;
          });
      } else {
        return true;
      }
    },
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    async getNewsList() {
      await this.$store.dispatch("review/getNewsTop3List");
    },
    async likeCheck(params) {
      if (!this.needsLogin()) return false;

      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }

      await this.$store.dispatch("wish/updateWish", params);
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("review/updateTop3Wish", {
          seq: params.review_seq,
          reviewer: params.reviewer_seq,
          wishYn: params.wish_yn,
          type: params.type,
        });
      }
      // if (params.type === "review") {
      //   const url = `/wish/review?menu=${this.newsMenu}`;
      //   this.$router.push(url);
      // }
    },
  },
};
</script>

<style></style>
