<template>
  <div></div>
</template>

<script>
export default {
  created() {
    let zone = "10881283";
    let adType = "banner";
    let bannerType = "floating";
    let width = "320";
    let height = "50";
    let id = "mobwith_" + zone;
    let pb = "";
    let position = "B";
    let marginHeight = "45"; // 플로팅 위아래 여백
    let closePosition = ""; // 플로팅 닫기 위치 : TL, TR, BL, BR
    let isFloatClose = "true"; // 닫기 버튼 생성 여부  true : 플로팅 닫기 버튼 O  , false : 플로팅 닫기 버튼 x
    let invisibleClose = "true"; // 투명 닫기 생성 여부 true : 투명 닫기 버튼 O , false : 투명 닫기 버튼 X

    let ad = new MobWithAd({
      id: id,
      zone: zone,
      adType: adType,
      bannerType: bannerType,
      width: width,
      height: height,
      pb: pb,
      position: position,
      marginHeight: marginHeight,
      closePosition: closePosition,
      isFloatClose: isFloatClose,
      invisibleClose: invisibleClose,
    });
  },
};
</script>

<style></style>
