export default [
  {
    name: "Cs",
    path: "/cs",
    redirect: "/cs/notice",
    component: () => import("@/views/cs/Cs"),
    children: [
      {
        name: "Notice",
        path: "notice",
        component: () => import("@/components/cs/Notice"),
      },
      {
        name: "Faq",
        path: "faq",
        component: () => import("@/components/cs/Faq"),
      },
      {
        name: "Qna",
        path: "qna",
        component: () => import("@/components/cs/Qna"),
        meta: {
          loginState: true,
        },
      },
    ],
  },
];
