import Vue from "vue";
import axios from "axios";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  email,
  max,
  min,
  numeric,
  required,
  digits,
  confirmed,
} from "vee-validate/dist/rules";

const sellingLicenseCheck = (number) => {
  let numberMap = number
    .replace(/-/gi, "")
    .split("")
    .map(function (d) {
      return parseInt(d, 10);
    });
  if (numberMap.length === 10) {
    let keyList = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;
    keyList.forEach(function (d, i) {
      chk += d * numberMap[i];
    });
    chk += parseInt((keyList[8] * numberMap[8]) / 10, 10);
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
  }
  return false;
};

extend("digits", {
  ...digits,
  message: "{_field_}는 {length} 자리 여야합니다. ({_value_})",
});
extend("email", {
  ...email,
  message: "잘못 입력된 이메일 주소입니다.",
});
extend("max", {
  ...max,
  message: "{_field_} 필드는 {length}자를 초과 할 수 없습니다.",
});
extend("min", {
  ...min,
  message: "{_field_} 필드는 {length}자 미만 이어야 합니다.",
});
extend("min_length", {
  validate(value, { length }) {
    if (value.length >= length) {
      return true;
    }
    return "{_field_}는 {length} 글자 이상이어야 합니다.";
  },
  params: ["length"],
});
extend("numeric", {
  ...numeric,
  message: "{_field_} 필드는 숫자로만 구성되어야합니다.",
});
extend("required", {
  ...required,
  message: "{_field_} 필드는 필수값 입니다.",
});
extend("confirmed", {
  ...confirmed,
  message: "비밀번호와 비밀번호 확인이 일치하지 않습니다.",
});
extend("new_confirmed", {
  ...confirmed,
  message: "새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.",
});
extend("uniqId", {
  async validate(value) {
    try {
      const res = await axios.post("/ramm_api/member/id_chk", {
        userid: value,
      });
      if (res.data.result) {
        return true;
      }
      return res.data.msg;
    } catch (error) {
      return "서버오류 입니다.";
    }
  },
});
extend("uniqNickname", {
  async validate(value) {
    try {
      const res = await axios.post("/ramm_api/member/nickname_chk", {
        nickname: value,
      });
      if (res.data.result) {
        return true;
      }
      return res.data.msg;
    } catch (error) {
      return "서버오류 입니다.";
    }
  },
});
extend("vaildNickname", {
  validate(value) {
    const phoenReg = /[^a-z0-9가-힝\-_]/gi;
    if (!phoenReg.test(value)) {
      return true;
    }
    return "{_field_}는 '영문,한글,숫자,-,_' 만 사용할 수 있습니다.";
  },
});
extend("cellphone", {
  validate(value) {
    const phoenReg = /^01([0|1|6|7|8|9])([0-9]{4})([0-9]{4})$/;
    if (phoenReg.test(value)) {
      return true;
    }
    return "{_field_}는 '-' 없는 휴대폰 번호 형식으로 입력해주세요.";
  },
});
extend("phone", {
  validate(value) {
    const phoenReg =
      /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|7[0]))(\d{3,4})(\d{4})$/;
    if (phoenReg.test(value)) {
      return true;
    }
    return "{_field_}는 '-' 없는 회사번호를 입력해주세요.";
  },
});
extend("sellingLicense", {
  validate(value) {
    if (sellingLicenseCheck(value)) {
      return true;
    }
    return "{_field_}는 '-' 없는 사업자등록번호 형식을 입력해주세요.";
  },
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
