export default [
  {
    name: "Introduce",
    path: "/introduce",
    redirect: "/introduce/info",
    component: () => import("@/views/introduce/Introduce"),
    children: [
      {
        name: "IntroduceContent",
        path: "info",
        component: () => import("@/components/introduce/Info"),
      },
      {
        name: "IntroduceBusiness",
        path: "business",
        component: () => import("@/components/introduce/Business"),
      },
      {
        name: "IntroduceTrems",
        path: "terms",
        component: () => import("@/components/introduce/Terms"),
      },
      {
        name: "IntroducePrivacy",
        path: "privacy",
        component: () => import("@/components/introduce/Privacy"),
      },
    ],
  },
];
