import { shop as call } from "@/api/call";
import _uniqBy from "lodash/uniqBy";
import _cloneDeep from "lodash/cloneDeep";

export default {
  namespaced: true,
  state: () => ({
    result: false,
    msg: null,
    categories: [],
    copyCategories: [],
    goodsInfo: {},
    optionData: [],
    subOptions: [],
    assign: [],
    mainCategory: [],
    listArray: [],
    totalPage: 0,
    categoryName: [],
    basket: [],
    limit: 0,
    lowCategorise2: [],
    lowCategorise3: [],
    lowCategorise4: [],
    reviewGoodsMenu: [],
    providerInfo: [],
  }),
  getters: {},
  mutations: {
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key === "listArray") {
          state[key] = [...state[key], ...payload[key]];
        } else {
          state[key] = payload[key];
        }
      });
    },
    dataClear(state) {
      state.result = false;
      state.msg = null;
      state.categories = [];
      state.copyCategories = [];
      state.goodsInfo = {};
      state.optionData = [];
      state.subOptions = [];
      state.assign = [];
      state.mainCategory = [];
      state.listArray = [];
      state.totalPage = 0;
      state.categoryName = [];
      state.basket = [];
      state.limit = 0;
      state.lowCategorise2 = [];
      state.lowCategorise3 = [];
      state.lowCategorise4 = [];
      state.reviewGoodsMenu = [];
      state.providerInfo = [];
    },
    resetData(state) {
      state.categories = [];
      state.categoryName = [];
      state.basket = [];
      state.copyCategories = [];
    },
    resetListArray(state) {
      state.listArray = [];
      state.totalPage = 0;
      state.limit = 0;
    },
    addOptions(state, { data, idx, max }) {
      data.map((dt) => {
        let add_pice =
          parseInt(dt.price) - parseInt(state.goodsInfo.sale_price);
        dt.opt_string = dt.opt;
        dt.add_pice = add_pice;
        if (parseInt(idx) === parseInt(max) - 1) {
          if (add_pice > 0) {
            dt.opt_string = `${dt.opt} (+ ${add_pice.toLocaleString()}&#8361;)`;
          } else if (add_pice < 0) {
            dt.opt_string = `${dt.opt} (- ${(
              add_pice * -1
            ).toLocaleString()}&#8361;)`;
          } else {
            dt.opt_string = dt.opt;
          }
        }
      });
      state.optionData[idx].options = [...data];
    },
    updateOptions(state, payload) {
      if (payload.type === "add") {
        state.basket.push(payload.data);
      } else {
        state.basket.splice(payload.idx, 1);
      }
    },
    updateSubOptions(state, payload) {
      if (payload.type === "add") {
        if (!state.basket[payload.idx].cart_suboptions) {
          state.basket[payload.idx].cart_suboptions = [];
        }
        state.basket[payload.idx].cart_suboptions.push(payload.data);
      } else {
        state.basket[payload.parentIdx].cart_suboptions.splice(payload.idx, 1);
      }
    },
    setOptionCnt(state, payload) {
      if (payload.type === "main") {
        state.basket[payload.idx].ea = payload.cnt;
      } else {
        state.basket[payload.parentIdx].cart_suboptions[payload.idx].ea =
          payload.cnt;
      }
    },
    deleteQna(state, { seq }) {
      const idx = state.listArray.findIndex((lilst) => lilst.seq === seq);
      state.listArray.splice(idx, 1);
    },
    modifyQna(state, { seq, content }) {
      state.listArray.map((list) => {
        if (list.seq === seq) {
          return (list.contents = content.replace(
            /(?:\r\n|\r|\n)/g,
            "\r\n<br />"
          ));
        }
      });
    },
    clearLowCategory(state, level) {
      if (parseInt(level) === 3) {
        state.lowCategorise2 = [];
        state.lowCategorise3 = [];
        state.lowCategorise4 = [];
      } else if (parseInt(level) === 4) {
        state.lowCategorise3 = [];
        state.lowCategorise4 = [];
      } else if (parseInt(level) === 5) {
        state.lowCategorise4 = [];
      }
    },
    updateWishShop(state, yn) {
      state.providerInfo.wish_yn = yn;
    },
    sortListArray(state, payload) {
      const listCnt = state.listArray.length;
      let tmpArray = [];
      let index = state.listArray.findIndex(
        (item) => parseInt(item[0].id) == parseInt(payload.id)
      );

      if (index !== -1) {
        state.listArray.map((item, idx) => {
          let tmp = listCnt + idx - index;
          if (tmp >= listCnt) {
            tmp = tmp - listCnt;
          }

          tmpArray[tmp] = item;

          // console.log(listCnt + index - index - idx, listCnt, tmp);
          // console.log(tmp, item, tmpArray[tmp]);
        });
        state.listArray = tmpArray;
      }
    },
    categorySort(state, payload) {
      const listCnt = state.categories.length;
      state.copyCategories = state.categories;
      let tmpArray = [];
      let index = state.categories.findIndex(
        (item) => parseInt(item.id) == parseInt(payload.id)
      );

      if (index !== -1) {
        state.categories.map((item, idx) => {
          let tmp = listCnt + idx - index;
          if (tmp >= listCnt) {
            tmp = tmp - listCnt;
          }

          tmpArray[tmp] = item;

          // console.log(listCnt + index - index - idx, listCnt, tmp);
          // console.log(tmp, item, tmpArray[tmp]);
        });
        state.copyCategories = tmpArray;
      }
    },
  },
  actions: {
    async bestGoodList({ commit }, payload) {
      commit("dataClear");
      try {
        const res = await call.bestGoodList({
          ...payload,
        });
        const { result, msg, data } = res.data; //데이터 구조 분해
        const categoryGoods = _cloneDeep(data); //받아온 값을 깊은 복사 참조 복사 아님
        const goodsInfo = _uniqBy(categoryGoods, "goods_seq"); // goods_seq 키로 중복 제거
        const categories = _uniqBy(data, "id"); //category id키로 중복 제거
        if (result) {
          commit("updateData", {
            categories,
            goodsInfo,
            msg,
            result,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("bestGoodList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getGoods({ commit }, seq) {
      commit("dataClear");
      try {
        const res = await call.getGoods(seq);
        const { result, msg, data } = res.data; //데이터 구조 분해
        const { goods, assign, option_data, suboptions } = data;
        if (result) {
          commit("updateData", {
            goodsInfo: goods,
            assign: assign,
            optionData: option_data || null,
            subOptions: suboptions || null,
            msg,
            result,
          });
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getGoods", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getMainCategory({ commit }) {
      try {
        const res = await call.getMainCategory();
        const { result, msg, data } = res.data;
        commit("updateData", {
          mainCategory: data,
          result: result,
        });
      } catch (error) {
        console.log("getMainCategory", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getCategories({ commit, state }) {
      try {
        const res = await call.getCategories();
        const { result, msg, data } = res.data;
        commit("updateData", {
          categories: data.category,
          copyCategories: data.category,
          reviewGoodsMenu: data.menu,
          result: result,
        });
      } catch (error) {
        console.log("getCategories", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getGoodsLowCategory({ commit }, payload) {
      try {
        const res = await call.getGoodsLowCategory(payload);
        const { result, msg, data } = res.data;
        commit("clearLowCategory", parseInt(payload.level));
        if (parseInt(payload.level) === 3) {
          commit("updateData", {
            lowCategorise2: data,
            result,
            msg,
          });
          if (payload.is_sort) {
            commit("categorySort", payload);
          }
        } else if (parseInt(payload.level) === 4) {
          commit("updateData", {
            lowCategorise3: data,
            result,
            msg,
          });
        } else if (parseInt(payload.level) === 5) {
          commit("updateData", {
            lowCategorise4: data,
            result,
            msg,
          });
        } else {
          commit("updateData", {
            msg: "존재하지 않는 레벨",
            result,
          });
        }
      } catch (error) {
        console.log("getGoodsLowCategory", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async moreOption({ commit }, { query, idx, max, type }) {
      try {
        const res = await call.moreOption(query);
        if (type === "shop") {
          commit("addOptions", {
            max,
            idx,
            data: res.data,
          });
        } else {
          commit(
            "order/addOptions",
            {
              max,
              idx,
              data: res.data,
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log("moreOption", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getMarketMainList({ commit }, payload) {
      try {
        const res = await call.getMarketMainList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("resetListArray");
          }
          if (payload.first) {
            commit("updateData", {
              categories: data.category,
              listArray: data.list,
              totalPage: data.total_page,
              limit: data.limit,
              result,
              msg,
            });
          } else {
            commit("updateData", {
              listArray: data.list,
              totalPage: data.total_page,
              limit: data.limit,
              result,
              msg,
            });
          }
          commit("sortListArray", payload);
        }
      } catch (error) {
        console.log("getMarketMainList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getMarketSubList({ commit }, payload) {
      try {
        const res = await call.getMarketSubList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("resetListArray");
          }
          commit("updateData", {
            categories: data.category,
            listArray: data.list,
            totalPage: data.total_page,
            categoryName: data.category_name.category,
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
            categories: data.category,
            categoryName: data.category_name.category,
            totalPage: data.total_page,
          });
        }
      } catch (error) {
        console.log("getMarketSubList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getGoodsList({ commit, state }, payload) {
      try {
        const res = await call.getGoodsList(payload);
        const { result, msg, data } = res.data;
        // if (result) {
        if (payload.reset) {
          commit("resetListArray");
        }
        commit("updateData", {
          categories: !payload.categoryNew ? data.category : state.categories,
          listArray: data.list,
          totalPage: data.total_page,
          categoryName: data.category_name.category,
          limit: data.limit,
          result,
          msg,
        });
        // }
      } catch (error) {
        console.log("getGoodsList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getGoodsDetail({ commit }, payload) {
      try {
        commit("dataClear");
        const res = await call.getGoodsDetail(payload);
        const { result, msg, data } = res.data;
        const { goods, assign, option_data, suboptions } = data.goods_data;
        commit("updateData", {
          categories: data.category,
          categoryName: data.category_name.category,
          goodsInfo: goods,
          assign: assign,
          optionData: option_data || null,
          subOptions: suboptions || null,
          result,
          msg,
        });
      } catch (error) {
        console.log("getGoodsDetail", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getQnaList({ commit }, payload) {
      try {
        const res = await call.getQnaList(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("resetListArray");
          }
          commit("updateData", {
            listArray: data.list,
            totalPage: data.total_page,
            limit: data.limit,
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("getQnaList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async qnaWrite({ commit }, payload) {
      try {
        const res = await call.qnaProcess(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("qnaWrite", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deleteQna({ commit }, payload) {
      try {
        const res = await call.qnaProcess(payload.formData);
        const { result, msg, data } = res.data;
        if (result) {
          commit("deleteQna", payload);
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("deleteQna", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async modifyQna({ commit }, payload) {
      try {
        const res = await call.qnaProcess(payload.formData);
        const { result, msg, data } = res.data;
        if (result) {
          commit("modifyQna", payload);
        }
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("modifyQna", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setAdd({ commit }, payload) {
      try {
        const res = await call.setAdd(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          result,
          msg,
        });
      } catch (error) {
        console.log("setAdd", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getMiniShop({ commit }, payload) {
      try {
        const res = await call.getMiniShop(payload);
        const { result, msg, data } = res.data;
        if (result) {
          if (payload.reset) {
            commit("resetListArray");
          }
          commit("updateData", {
            providerInfo: data.provider_info,
            listArray: data.list,
            limit: data.limit,
            totalPage: data.total_page,
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("getMiniShop", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getSellerInfo({ commit }, payload) {
      try {
        const res = await call.getSellerInfo(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          providerInfo: data,
          result,
          msg,
        });
      } catch (error) {
        console.log("getSellerInfo", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    updateOptions({ commit }, payload) {
      commit("updateOptions", payload);
    },
    updateSubOptions({ commit }, payload) {
      commit("updateSubOptions", payload);
    },
    setOptionCnt({ commit }, payload) {
      commit("setOptionCnt", payload);
    },
    orderDataInit({ commit }) {
      commit("orderDataInit");
    },
    dataClear({ commit }) {
      commit("dataClear");
    },
    resetListArray({ commit }) {
      commit("resetListArray");
    },
    clearShopCategories({ commit }) {
      commit("clearShopCategories");
    },
    clearLowCategory({ commit }, level) {
      commit("clearLowCategory", level);
    },
    updateWishShop({ commit }, yn) {
      commit("updateWishShop", yn);
    },
  },
};
