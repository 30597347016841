import { instance } from "@/api/http";

export default {
  getWishMenu(payload = {}) {
    return instance({
      url: "/ramm_api/wish/get_wish_menu",
      method: "post",
      data: payload,
    });
  },
  getWishData(payload = {}) {
    return instance({
      url: "/ramm_api/wish/get_wish_data",
      method: "post",
      data: payload,
    });
  },
  updateWish(payload = {}) {
    return instance({
      url: "/ramm_api/wish/update_wish",
      method: "post",
      data: payload,
    });
  },
  updateWishAlarm(payload = {}) {
    return instance({
      url: "/ramm_api/wish/update_wish_alarm",
      method: "post",
      data: payload,
    });
  },
  updateWishShop(payload = {}) {
    return instance({
      url: "/ramm_api/wish/update_wish_shop",
      method: "post",
      data: payload,
    });
  },
  updateWishShopAlarm(payload = {}) {
    return instance({
      url: "/ramm_api/wish/update_wish_shop_alarm",
      method: "post",
      data: payload,
    });
  },
};
