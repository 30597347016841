import Vue from "vue";
import Vuex from "vuex";
import common from "@/store/common";
import member from "@/store/member";
import shop from "@/store/shop";
import mypage from "@/store/mypage";
import review from "@/store/review";
import order from "@/store/order";
import wish from "@/store/wish";
import rank from "@/store/rank";
import search from "@/store/search";
import cs from "@/store/cs";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    member,
    shop,
    mypage,
    review,
    order,
    wish,
    rank,
    search,
    cs,
  },
});
