import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Meta from "vue-meta";
import "./plugins";
import vueCommon from "./plugins/vueCommon";
import VueSimpleAlert from "vue-simple-alert";
import VueMoment from "vue-moment";
import "moment/locale/ko";
import VueToast from "vue-toast-notification";
// Import one of the available themes
//import "vue-toast-notification/dist/theme-default.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "./registerServiceWorker";
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.config.productionTip = false;
Vue.use(Meta, { refreshOnceOnNavigation: true });
Vue.use(vueCommon);
Vue.use(VueMoment);
Vue.use(VueSimpleAlert);
Vue.use(VueAwesomeSwiper);
Vue.use(VueToast, { position: "top", queue: true, duration: 2000 });

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
window.ramm = app;
