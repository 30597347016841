<template>
  <div v-if="isLoading">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="spinner"
      style="
        margin: auto;
        background: transparent;
        display: block;
        shape-rendering: auto;
      "
      :width="size"
      :height="size"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#ffd040"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
      <!-- [ldio] generated by https://loading.io/ -->
    </svg>
    <div class="layer"></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    size: {
      default: "50px",
    },
  },
  computed: {
    styles() {
      return {
        width: this.size,
        height: this.size,
      };
    },
    ...mapState("common", ["isLoading"]),
  },
};
</script>
<style lang="scss" scoped>
.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}
.layer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $black;
  z-index: 9;
  opacity: 0;
  top: 0;
  left: 0;
}
</style>
