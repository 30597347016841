export default {
  install(Vue) {
    //Vue3 app.config.globalProperties 으로 대체
    Vue.prototype.$won = (cost) => Math.floor(Number(cost)).toLocaleString();

    Vue.prototype.$decodeHtmlEntity = (str) => {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = str;
      const result = textarea.value;
      textarea.remove();
      return result;
    };

    Vue.prototype.$loadImage = (src) => {
      return new Promise((resolve) => {
        const img = document.createElement("img");
        img.src = src;
        img.addEventListener("load", () => {
          resolve();
        });
      });
    };

    Vue.prototype.$rammFloat = (num) => {
      const score = Math.round(Number(num) * 10) / 10;
      if (Number.isInteger(score)) {
        return `${score}.0`;
      }
      return score;
    };
  },
};
