<template>
  <div>
    <div id="header">
      <h1>
        <router-link to="/"
          ><img src="@/assets/images/m_header_logo.png" alt=""
        /></router-link>
      </h1>
    </div>
    <div id="wrapper">
      <div class="wrapper">
        <div class="m_s_tab">
          <div class="on">
            <router-link to="" @click.native="chgSearch('1', $event)"
              >리뷰 놀이터</router-link
            >
          </div>
          <div>
            <router-link to="" @click.native="chgSearch('2', $event)"
              >램 장터</router-link
            >
          </div>
          <!-- <div>&nbsp;</div> -->
        </div>
        <div class="m_s_search type_1">
          <ul>
            <li>
              <select
                name=""
                @change="chgSelect($event)"
                v-if="parseInt(this.seachType) === 1"
              >
                <option value="1">상품</option>
                <option value="3">뉴스</option>
              </select>
              <select
                name=""
                @change="chgSelect($event)"
                v-if="parseInt(this.seachType) === 2"
              >
                <option value="1">상품</option>
                <!-- <option value="">뉴스</option> -->
              </select>
            </li>
            <li>
              <input
                type="text"
                name=""
                v-model="searchText"
                placeholder="램에서 검색하세요!"
                @keydown.enter="goSearch"
              />
            </li>
            <li><router-link to="" @click.native="goSearch"></router-link></li>
          </ul>
        </div>
        <div class="m_s_cate type_1">
          <div>
            <router-link
              to=""
              @click.native="toMove('goods_main', 'market_main_list')"
              ><img src="@/assets/images/m_cate_ico_01.png" alt="" />
              <p>상품</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_02.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">책/음반</p></router-link
            >
          </div>
          <div v-if="parseInt(this.seachType) === 1">
            <router-link to="" @click.native="toMove('news_main', null)"
              ><img src="@/assets/images/m_cate_ico_03.png" alt="" />
              <p>뉴스</p></router-link
            >
          </div>
          <div v-if="parseInt(this.seachType) === 2">
            <router-link to="">
              <img
                src="@/assets/images/m_cate_ico_03.png"
                class="hiden_menu"
                alt=""
              />
            </router-link>
            <p class="hiden_menu">뉴스</p>
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_04.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">영화</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_05.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">음악</p></router-link
            >
          </div>
          <!-- <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_06.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">학원</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_07.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">병원</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_08.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">헬스</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_09.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">뷰티</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_10.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">보험</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_11.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">금융</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_12.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">증권</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_13.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">부동산</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_14.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">여행</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_15.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">맛집</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_16.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">공연/전시</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_17.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">게임</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_18.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">스포츠</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_19.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">웹툰</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_20.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">컨설팅</p></router-link
            >
          </div>
          <div>
            <router-link to=""
              ><img
                src="@/assets/images/m_cate_ico_21.png"
                alt=""
                class="hiden_menu"
              />
              <p class="hiden_menu">기타</p></router-link
            >
          </div> -->
        </div>
        <TodayGoodsAndReview />
      </div>
    </div>
  </div>
</template>

<script>
import TodayGoodsAndReview from "@/components/main/TodayGoodsAndReview";
export default {
  data() {
    return {
      seachType: 1,
      searchText: "",
      menu: 1,
    };
  },
  components: {
    TodayGoodsAndReview,
  },
  mounted() {
    this.loginCheck();
  },
  methods: {
    async loginCheck() {
      await this.$store.dispatch("member/loginState");
    },
    chgSelect(event) {
      const value = event.target.value;
      this.menu = parseInt(value);
    },
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      if (parseInt(this.seachType) === 1) {
        this.$router.push(
          `/search/review_search/${this.menu}?search=${this.searchText}`
        );
      } else if (parseInt(this.seachType) === 2) {
        this.$router.push(`/search/goods_search?search=${this.searchText}`);
      } else {
        return false;
      }
    },
    chgSearch(num, e) {
      const parentDiv = document.querySelector(".m_s_tab");

      this.seachType = parseInt(num);
      let j = 0;
      for (let i = 1; i <= parentDiv.childNodes.length; i++) {
        parentDiv.childNodes[j].classList.remove("on");
        document.querySelector(".m_s_search").classList.remove(`type_${i}`);
        document.querySelector(".m_s_cate").classList.remove(`type_${i}`);
        j++;
      }
      e.target.closest("div").classList.add("on");
      document.querySelector(".m_s_search").classList.add(`type_${num}`);
      document.querySelector(".m_s_cate").classList.add(`type_${num}`);
    },
    toMove(review, goods) {
      if (parseInt(this.seachType) === 1) {
        if (!review) {
          this.$toast.default("준비중 입니다.");
          return false;
        }
        this.$router.push({ path: `/review/${review}` });
        return false;
      }
      if (parseInt(this.seachType) === 2) {
        if (!goods) {
          this.$toast.default("준비중 입니다.");
          return false;
        }
        this.$router.push({ path: `/${goods}` });
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hiden_menu {
  opacity: 0.3;
  //filter: blur(1.5px);
}
</style>
