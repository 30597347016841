import { instance } from "@/api/http";

export default {
  getMainMenu(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_main_menu",
      method: "post",
      data: payload,
    });
  },
  getSubMenu(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_sub_menu",
      method: "post",
      data: payload,
    });
  },
  getReviewMenu(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_review_menu",
      method: "post",
      data: payload,
    });
  },
  getReviewList(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_review_list",
      method: "post",
      data: payload,
    });
  },
  getReviewEvaluation(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_review_evaluation",
      method: "post",
      data: payload,
    });
  },
  submitMainImg(payload = {}) {
    return instance({
      url: "/ramm_api/review/submit_main_Img",
      method: "post",
      data: payload,
    });
  },
  getMetaData(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_meta_tags",
      method: "post",
      data: payload,
    });
  },
  getCategoryTitle(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_category_title",
      method: "post",
      data: payload,
    });
  },
  setNewsReviewRegister(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_news_review_register",
      method: "post",
      data: payload,
    });
  },
  setNormalGoodsReviewRegister(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_normal_goods_review_register",
      method: "post",
      data: payload,
    });
  },
  setSellerGoodsReviewRegister(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_seller_goods_review_register",
      method: "post",
      data: payload,
    });
  },
  setProGoodsReviewRegister(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_pro_goods_review_register",
      method: "post",
      data: payload,
    });
  },
  editorImgUpload(payload = {}) {
    return instance({
      url: "/ramm_api/review/submit_editor_img",
      method: "post",
      data: payload,
    });
  },
  getGoodsInfo(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_goods_info",
      method: "post",
      data: payload,
    });
  },
  getReviewGoodsList(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_review_goods_list",
      method: "post",
      data: payload,
    });
  },
  setViewHit(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_view_hit",
      method: "post",
      data: payload,
    });
  },
  setNewsHit(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_news_hit",
      method: "post",
      data: payload,
    });
  },
  setMakeReviewGoodsScore(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_make_review_goods_score",
      method: "post",
      data: payload,
    });
  },
  setMakeReviewNewsScore(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_make_review_news_score",
      method: "post",
      data: payload,
    });
  },
  writeReply(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_write_reply",
      method: "post",
      data: payload,
    });
  },
  getReplyList(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_replay_list",
      method: "post",
      data: payload,
    });
  },
  getReReplyList(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_re_replay_list",
      method: "post",
      data: payload,
    });
  },
  setReplyDeclaration(payload = {}) {
    return instance({
      url: "/ramm_api/review/set_reply_declaration",
      method: "post",
      data: payload,
    });
  },
  getNewsMenu(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_news_menu",
      method: "get",
      data: payload,
    });
  },
  getRealTimeNews(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_real_time_news",
      method: "get",
      data: payload,
    });
  },
  getNewsList(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_news_list",
      method: "post",
      data: payload,
    });
  },
  getNewsTop3List(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_review_goods_top3_list",
      method: "post",
      data: payload,
    });
  },
  getGoodsNomalReview(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_goods_nomal_review",
      method: "post",
      data: payload,
    });
  },
  getGoodsProReview(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_goods_pro_review",
      method: "post",
      data: payload,
    });
  },
  getNewsReview(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_news_review",
      method: "post",
      data: payload,
    });
  },
  deleteReview(payload = {}) {
    return instance({
      url: "/ramm_api/review/delete_review",
      method: "post",
      data: payload,
    });
  },
  getEndCategory(payload = {}) {
    return instance({
      url: "/ramm_api/review/get_end_category",
      method: "post",
      data: payload,
    });
  },
};
