export default [
  {
    name: "Shop",
    path: "/shop",
    component: () => import("@/views/shop/Shop"),
  },
  {
    name: "Goods",
    path: "/shop/goods/:seq",
    component: () => import("@/views/shop/Goods"),
  },
  // {
  //   name: "Market",
  //   path: "/market",
  //   component: () => import("@/views/shop/MarketMain"),
  //   children: [
  //     {
  //       name: "MarketCategoryListNew",
  //       path: "category_list",
  //       component: () => import("@/components/shop/menu/MarketCategoryList"),
  //     },
  //     {
  //       name: "MarketMainListNew",
  //       path: "main_list",
  //       component: () => import("@/components/shop/menu/MarketMainList"),
  //     },
  //   ],
  // },
  {
    name: "MarketMainList",
    path: "/market_main_list",
    component: () => import("@/views/shop/MarketMainList"),
  },
  {
    name: "MarketCategoryList",
    path: "/market_category_list",
    component: () => import("@/views/shop/MarketCategoryList"),
    meta: {
      main: false,
    },
  },
  {
    name: "MarketMainListId",
    path: "/market_main_list/:id",
    component: () => import("@/views/shop/MarketMainList"),
  },
  {
    name: "MarketSubList",
    path: "/market_sub_list/:level/:id",
    component: () => import("@/views/shop/MarketSubList"),
  },
  {
    name: "MarketGoodsList",
    path: "/market_goods_list/:level/:id",
    component: () => import("@/views/shop/MarketGoodsList"),
  },
  {
    name: "MarketGoodsDetail",
    path: "/market_goods_detail/:categoryId/:goodsSeq",
    component: () => import("@/views/shop/MarketGoodsDetail"),
    meta: {
      loginState: true,
    },
  },
  {
    name: "MarketGoodsDetailQna",
    path: "/market_goods_detail/:categoryId/:goodsSeq/:qna",
    component: () => import("@/views/shop/MarketGoodsDetail"),
    meta: {
      loginState: true,
    },
  },
  {
    name: "SellerInfo",
    path: "/seller_info/:provider_id",
    component: () => import("@/views/shop/SellerInfo"),
    meta: {
      loginState: true,
    },
  },
  {
    name: "CautionInfo",
    path: "/caution_info/:provider_id",
    component: () => import("@/views/shop/CautionInfo"),
    meta: {
      loginState: true,
    },
  },
  {
    name: "OrderPayment",
    path: "/order_payment/:category/:goodsSeq/:mode",
    component: () => import("@/views/shop/OrderPayment"),
    meta: {
      auth: true,
    },
  },
  {
    name: "OrderPaymentCart",
    path: "/order_payment/cart/:mode",
    component: () => import("@/views/shop/OrderPayment"),
    meta: {
      auth: true,
    },
  },
  {
    name: "MiniShopMain",
    path: "/mini_shop/:providerSeq",
    component: () => import("@/views/shop/MiniShopMain"),
    meta: {
      loginState: true,
    },
  },
];
