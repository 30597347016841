var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"main_tit"},[_vm._v("오늘의 상품 리뷰")]),_c('div',{staticClass:"m_s_review"},_vm._l((_vm.top3List.goods_review),function(item){return _c('div',{key:item.seq,staticClass:"box"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.thumbnail,"alt":"상품 리뷰 썸네일"},on:{"error":function($event){return _vm.replaceGoodsImg($event, item.goods_img)},"click":function($event){return _vm.$router.push(
                ("/review/goods_main/" + (item.category_code) + "?goods_seq=" + (item.goods_seq) + "&option1=" + (item.option_category1) + "&option2=" + (item.option_category2) + "&option3=" + (item.option_category3) + "&seq=" + (item.seq))
              )}}})]),_c('div',{staticClass:"txt",on:{"click":function($event){return _vm.$router.push(
              ("/review/goods_main/" + (item.category_code) + "?goods_seq=" + (item.goods_seq) + "&option1=" + (item.option_category1) + "&option2=" + (item.option_category2) + "&option3=" + (item.option_category3) + "&seq=" + (item.seq))
            )}}},[_c('h3',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_vm._v("상품명 : "+_vm._s(item.main_goods_name))])])]),_c('ul',{staticClass:"bottom"},[_c('li',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.likeCheck({
                wish_yn: item.wish_yn_reviwer,
                reviewer_seq: item.member_seq,
                review_seq: null,
                main_seq: item.main_menu_seq,
                type: 'reviewer',
              })}}},[(item.wish_yn_reviwer == 'Y')?_c('img',{attrs:{"src":require("@/assets/images/sub/like_on.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/sub/like_off.png"),"alt":""}})]),_c('router-link',{attrs:{"to":""}},[_vm._v(_vm._s(item.nickname))])],1),_c('li',[_vm._v("리뷰어 신뢰도 "+_vm._s(_vm.rammFloat(item.re_trust_score)))]),_c('li',[_vm._v("제품 평점 "+_vm._s(_vm.rammFloat(item.main_score)))])])])}),0),_c('h2',{staticClass:"main_tit"},[_vm._v("오늘의 뉴스 리뷰")]),_c('div',{staticClass:"m_s_review"},_vm._l((_vm.top3List.news),function(item){return _c('div',{key:item.seq,staticClass:"box"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.thumbnail,"alt":"뉴스 썸네일"},on:{"error":_vm.replaceNewsImg,"click":function($event){return _vm.$router.push(
                ("/review/news_main/" + (item.option_category1) + "?category=" + (item.option_category2) + "&seq=" + (item.seq))
              )}}})]),_c('div',{staticClass:"txt",on:{"click":function($event){return _vm.$router.push(
              ("/review/news_main/" + (item.option_category1) + "?category=" + (item.option_category2) + "&seq=" + (item.seq))
            )}}},[_c('h3',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.option1)+_vm._s(item.option2 ? ("> " + (item.option2)) : "")+" ")])])]),_c('ul',{staticClass:"bottom"},[_c('li',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.likeCheck({
                wish_yn: item.wish_yn_reviwer,
                reviewer_seq: item.member_seq,
                review_seq: null,
                main_seq: item.main_menu_seq,
                type: 'reviewer',
              })}}},[(item.wish_yn_reviwer == 'Y')?_c('img',{attrs:{"src":require("@/assets/images/sub/like_on.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/sub/like_off.png"),"alt":""}})]),_c('router-link',{attrs:{"to":""}},[_vm._v(_vm._s(item.nickname))])],1),_c('li',[_vm._v("리뷰어 신뢰도 "+_vm._s(_vm.rammFloat(item.re_trust_score)))]),_c('li',[_vm._v("중요도 "+_vm._s(_vm.rammFloat(item.news_total_score)))])])])}),0),_c('h2',{staticClass:"main_tit"},[_vm._v(" 오늘의 상품"),_c('router-link',{staticClass:"more",attrs:{"to":"/search/provider_search"}},[_vm._v("램 가게 보러가기 >")])],1),_c('div',{staticClass:"m_s_goods"},_vm._l((_vm.top3List.goods),function(item,index){return _c('div',{key:item.seq,staticClass:"box"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"img",on:{"click":function($event){return _vm.$router.push(
              ("/market_goods_detail/" + (item.category_id) + "/" + (item.goods_seq))
            )}}},[_c('img',{attrs:{"src":item.goods_img,"alt":""}})]),_c('div',{staticClass:"txt",on:{"click":function($event){return _vm.$router.push(
              ("/market_goods_detail/" + (item.category_id) + "/" + (item.goods_seq))
            )}}},[_c('h3',[_vm._v(_vm._s(item.goods_name))]),_c('p',[_vm._v("램 가게 > "+_vm._s(item.provider_name))])])]),_c('ul',{staticClass:"bottom"},[_c('li',[_vm._v("가격 : "+_vm._s(_vm.won(item.default_price))+"원")]),_c('li',[_vm._v(" ")]),_c('li',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.movePage(index)}}},[_vm._v("리뷰보기")])],1)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }