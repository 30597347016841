import { instance } from "@/api/http";

export default {
  sellerApply(payload = {}) {
    return instance({
      url: "/ramm_api/channel/seller_apply",
      method: "post",
      data: payload,
    });
  },
  channelChange(payload = {}) {
    return instance({
      url: "/ramm_api/channel/channel_change",
      method: "post",
      data: payload,
    });
  },
  reviewerRegister(payload = {}) {
    return instance({
      url: "/ramm_api/channel/new_reviewer_register",
      method: "post",
      data: payload,
    });
  },
};
