import { order as call } from "@/api/call";
export default {
  namespaced: true,
  state: () => ({
    result: false,
    msg: null,
    cartList: [],
    isAllCheck: true,
    totalAmount: 0,
    totalRowCnt: 0,
    totalCnt: 0,
    totalPage: 0,
    shippingPrice: 0,
    totalSales: 0,
    orderData: {},
    listArray: [],
    paymentData: {},
    position: 0,
    completeOrder: null,
    pgInfo: {},
    shppingCost: 0,
    returnData: null,
  }),
  getters: {},
  mutations: {
    updateData(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key === "listArray") {
          // 만약 리스트 만 업데이트 했을 경우
          state[key] = [...state[key], ...payload[key]];
        } else {
          state[key] = payload[key];
        }
      });
    },
    setLoopData(state) {
      //OrderItem component 에서 사용하기 위해 LOOP데이터 생성'
      if (state.orderData.exports) {
        state.orderData.loop = {
          key: {
            export_item: state.orderData.exports[0].item,
          },
        };
      }
    },
    dataClear(state) {
      state.result = false;
      state.msg = null;
      state.cartList = [];
      state.isAllCheck = true;
      state.totalAmount = 0;
      state.totalRowCnt = 0;
      state.totalCnt = 0;
      state.totalPage = 0;
      state.shippingPrice = 0;
      state.totalSales = 0;
      state.orderData = {};
      state.listArray = [];
      state.paymentData = {};
      state.position = 0;
      state.completeOrder = null;
      state.pgInfo = {};
      state.shppingCost = 0;
      state.returnData = null;
    },
    goodsChecked(state, payload) {
      state.isAllCheck = true;
      if (payload.isAll) {
        Object.keys(state.cartList.shipping_group_list).forEach((keys) => {
          state.cartList.shipping_group_list[keys].checked = payload.check;
          state.cartList.shipping_group_list[keys].goods.forEach((goods) => {
            goods.checked = payload.check;
          });
        });
      } else {
        if (payload.idx !== false) {
          let trusy = true;

          state.cartList.shipping_group_list[payload.group].goods[
            payload.idx
          ].checked = payload.check;

          state.cartList.shipping_group_list[payload.group].goods.forEach(
            (item) => {
              if (item.checked === false) {
                trusy = false;
              }
            }
          );
          state.cartList.shipping_group_list[payload.group].checked = trusy;
        } else {
          state.cartList.shipping_group_list[payload.group].checked =
            payload.check;
          state.cartList.shipping_group_list[payload.group].goods.forEach(
            (item) => {
              item.checked = payload.check;
            }
          );
        }
      }

      Object.keys(state.cartList.shipping_group_list).forEach((keys) => {
        if (!state.cartList.shipping_group_list[keys].checked) {
          state.isAllCheck = false;
        }
      });
    },
    deleteCartItem(state, paylaod) {
      const index = state.cartList.shipping_group_list[
        paylaod.key_name
      ].goods.findIndex(
        (item) =>
          parseInt(item.cart_option_seq) === parseInt(paylaod.cart_option_seq)
      );
      state.cartList.shipping_group_list[paylaod.key_name].goods.splice(
        index,
        1
      );
    },
    cartSet(state) {
      state.totalAmount = 0;
      state.totalRowCnt = 0;
      state.totalCnt = 0;
      state.shippingPrice = 0;
      state.totalSales = 0;
      state.shppingCost = 0;

      Object.keys(state.cartList.shipping_group_list).forEach((key) => {
        if (
          state.cartList.shipping_group_list[key].goods.some(
            (g) => g.checked === true
          )
        ) {
          state.shippingPrice += parseInt(
            state.cartList.shipping_group_list[key].grp_shipping_price
          );
        }
        state.cartList.shipping_group_list[key].goods.forEach((item) => {
          if (item.checked) {
            state.totalAmount += parseInt(item.tot_price);
            state.totalCnt += parseInt(item.ea);
            state.totalSales += parseInt(item.sales.total_sale_price);
            state.totalRowCnt++;
          }
        });
      });
    },
    orderDataInit(state) {
      //기본 구조를 만들어 줘야 에러 안남...
      state.orderData = {
        orders: {
          payment: null,
        },
        items_tot: {},
        items: [
          {
            options: [
              {
                mstep: {},
              },
            ],
          },
        ],
        shipping_tot: {},
        loop: {},
        return_reason: [],
        admin_member_seq: "",
        cart: {},
        cart_inputs: "",
        cart_options: [],
        cart_suboptions: "",
        cart_table: "",
        config_system: {},
        goods: {
          option_divide_title: [],
        },
        inputs: "",
        option_data: [],
        option_depth: "",
        options: [],
        options_join: [],
        origin_order_seq: "",
        select_option_mode: "",
        sessionMember: {},
        suboptions: [],
      };
    },
    updateOptions(state, payload) {
      if (payload.type === "add") {
        state.orderData.cart_options.push(payload.data);
      } else {
        state.orderData.cart_options.splice(payload.idx, 1);
      }
    },
    updateSubOptions(state, payload) {
      if (payload.type === "add") {
        if (!state.orderData.cart_options[payload.idx].cart_suboptions) {
          state.orderData.cart_options[payload.idx].cart_suboptions = [];
        }
        state.orderData.cart_options[payload.idx].cart_suboptions.push(
          payload.data
        );
      } else {
        state.orderData.cart_options[payload.parentIdx].cart_suboptions.splice(
          payload.idx,
          1
        );
      }
    },
    setOptionCnt(state, payload) {
      if (payload.type === "main") {
        state.orderData.cart_options[payload.idx].ea = payload.cnt;
      } else {
        state.orderData.cart_options[payload.parentIdx].cart_suboptions[
          payload.idx
        ].ea = payload.cnt;
      }
    },
    addOptions(state, { data, idx, max }) {
      data.map((dt) => {
        let add_pice =
          parseInt(dt.price) - parseInt(state.orderData.goods.sale_price);
        dt.opt_string = dt.opt;
        dt.add_pice = add_pice;
        if (parseInt(idx) === parseInt(max) - 1) {
          if (add_pice > 0) {
            dt.opt_string = `${dt.opt} (+ ${add_pice.toLocaleString()}&#8361;)`;
          } else if (add_pice < 0) {
            dt.opt_string = `${dt.opt} (- ${(
              add_pice * -1
            ).toLocaleString()}&#8361;)`;
          } else {
            dt.opt_string = dt.opt;
          }
        }
      });
      state.orderData.option_data[idx].options = [...data];
    },
    updateMembersData(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.paymentData.members[key] = payload[key];
      });
    },
    changeAddress(state, payload) {
      console.log(payload);
      state.paymentData.members.default_address.address =
        payload.recipient_address;
      state.paymentData.members.default_address.address_street =
        payload.recipient_address;
      state.paymentData.members.default_address.address_detail =
        payload.recipient_address_detail;
      state.paymentData.members.default_address.cellphone =
        payload.recipient_cellphone;
      state.paymentData.members.default_address.cellphone1 =
        payload.recipient_cellphone.split("-")[0];
      state.paymentData.members.default_address.cellphone2 =
        payload.recipient_cellphone.split("-")[1];
      state.paymentData.members.default_address.cellphone3 =
        payload.recipient_cellphone.split("-")[2];
      state.paymentData.members.default_address.phone =
        payload.recipient_phone.replace(/-/g, "")
          ? payload.recipient_phone
          : "";
      state.paymentData.members.default_address.user_name =
        payload.recipient_user_name;
      state.paymentData.members.default_address.zipcode =
        payload.recipient_zipcode;
    },
    appyReceipt(state) {
      state.orderData.orders.tax_receipt_possible = false;
      state.orderData.orders.typereceipt = "2";
    },
  },
  actions: {
    async getCartList({ commit, state }) {
      commit("updateData", {
        cartList: [],
        msg: "",
        result: false,
      });
      try {
        const res = await call.getCartList();
        const { result, msg, data } = res.data;
        if (data.shipping_group_list) {
          state.isAllCheck = true;
          Object.keys(data.shipping_group_list).forEach((keys) => {
            data.shipping_group_list[keys]["checked"] = state.isAllCheck;
            data.shipping_group_list[keys].goods.forEach((goods) => {
              goods.checked = state.isAllCheck;
            });
          });
          commit("updateData", {
            cartList: data,
            msg: msg,
            result: result,
          });
          commit("cartSet");
        }
      } catch (error) {
        console.log("getCartList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async deleteCart({ commit, dispatch }, payload) {
      try {
        const res = await call.deleteCart(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
        if (result) {
          dispatch("deleteCartItem", payload).then(() => {
            commit("cartSet");
          });
        }
      } catch (error) {
        console.log("deleteCart", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getOrderView({ commit }, payload) {
      commit("orderDataInit");
      try {
        const res = await call.getOrderView(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            orderData: data,
            msg,
            result,
          });
          commit("setLoopData");
        } else {
          commit("updateData", {
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getOrderView", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async orderCancel({ commit }, payload) {
      try {
        const res = await call.orderCancel(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("orderCancel", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async orderRefund({ commit }, payload) {
      commit("orderDataInit");
      try {
        const res = await call.orderRefund(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          orderData: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("orderRefund", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getOrderReturn({ commit }, payload) {
      commit("orderDataInit");
      try {
        const res = await call.getOrderReturn(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            orderData: data,
            msg,
            result,
          });
        }
      } catch (error) {
        console.log("getOrderReturn", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setOrderReturn({ commit }, payload) {
      try {
        const res = await call.setOrderReturn(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          msg,
          result,
          returnData: data.return_data || null,
        });
      } catch (error) {
        console.log("setOrderReturn", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getPaymentList({ commit }, payload) {
      if (parseInt(payload.page) <= 1 || payload.reset) {
        commit("dataClear", {
          listArray: [],
        });
      }
      try {
        const res = await call.getPaymentList(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          listArray: data.record,
          msg,
          result,
          totalPage: data.page.totalpage,
        });
      } catch (error) {
        console.log("getPaymentList", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getOptionalChanges({ commit }, payload) {
      commit("orderDataInit");
      try {
        const res = await call.getOptionalChanges(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          orderData: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("getOptionalChanges", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setOptionChange({ commit }, payload) {
      try {
        const res = await call.setOptionChange(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("setOptionChange", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setDeclaration({ commit }, payload) {
      try {
        const res = await call.setDeclaration(payload);
        const { result, msg } = res.data;
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("setDeclaration", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async setSettle({ commit }, payload) {
      try {
        commit("updateData", {
          paymentData: {},
        }); //초기화
        const res = await call.setSettle(payload);
        const { result, msg } = res.data;
        if (result) {
          commit("updateData", {
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("setSettle", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async getSettle({ commit }, payload) {
      try {
        commit("updateData", {
          paymentData: {},
        }); //초기화
        const res = await call.getSettle(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            paymentData: data,
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("getSettle", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async orderMemberUpdate({ commit }, payload) {
      try {
        const res = await call.orderMemberUpdate(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateMembersData", {
            cellphone: data.cellphone,
            user_name: data.user_name,
          });
          commit("updateData", {
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("orderMemberUpdate", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async choiceOne({ commit }, payload) {
      try {
        commit("updateData", {
          paymentData: {},
        }); //초기화
        const res = await call.choiceOne(payload.formData, payload.mode);
        const { result, msg } = res.data;
        if (result) {
          commit("updateData", {
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("choiceOne", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async payment({ commit }, payload) {
      try {
        commit("updateData", {
          completeOrder: null,
        }); //초기화
        const res = await call.payment(payload.formData, payload.mode);
        const { result, msg, data } = res.data;
        if (result) {
          commit("updateData", {
            completeOrder: data.order_seq,
            result,
            msg,
          });
        } else {
          commit("updateData", {
            result,
            msg,
          });
        }
      } catch (error) {
        console.log("payment", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async orderMail({ commit }, payload) {
      try {
        await call.orderMail(payload);
      } catch (error) {
        console.log("orderMail", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async applyCashReceipt({ commit }, payload) {
      try {
        const res = await call.applyCashReceipt(payload);
        const { result, msg, data } = res.data;
        if (result) {
          commit("applyCashReceipt");
        }
        commit("updateData", {
          msg,
          result,
        });
      } catch (error) {
        console.log("applyCashReceipt", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async viewCashReceipt({ commit }, payload) {
      try {
        const res = await call.viewCashReceipt(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          pgInfo: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("viewCashReceipt", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    async calculateShippingCost({ commit }, payload) {
      try {
        const res = await call.calculateShippingCost(payload);
        const { result, msg, data } = res.data;
        commit("updateData", {
          shppingCost: data,
          msg,
          result,
        });
      } catch (error) {
        console.log("calculateShippingCost", error);
        commit("updateData", {
          msg: "서버와 통신에 문제가 생겼습니다.",
          result: false,
        });
      }
    },
    changeAddress({ commit }, payload) {
      commit("changeAddress", payload);
    },
    updateOptions({ commit }, payload) {
      commit("updateOptions", payload);
    },
    updateSubOptions({ commit }, payload) {
      commit("updateSubOptions", payload);
    },
    setOptionCnt({ commit }, payload) {
      commit("setOptionCnt", payload);
    },
    goodsChecked({ commit }, payload) {
      commit("goodsChecked", payload);
      commit("cartSet");
    },
    orderDataInit({ commit }) {
      commit("orderDataInit");
    },
    setPosition({ commit }, payload) {
      commit("updateData", payload);
    },
    dataClear({ commit }) {
      commit("dataClear");
    },
    deleteCartItem({ commit }, payload) {
      commit("deleteCartItem", payload);
    },
  },
};
