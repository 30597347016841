<template>
  <div>
    <!-- <Header /> -->
    <Transition name="page">
      <router-view />
    </Transition>
    <Footer />
    <Loading size="50px" />
  </div>
</template>

<script>
// import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";
import Loading from "@/components/common/Loading";

export default {
  components: {
    // Header,
    Footer,
    Loading,
  },
};
</script>

<style lang="scss">
@import "assets/_css/reset.css";
@import "assets/_css/common.css";
@import "assets/_css/index.css";
@import "assets/_css/css.css";
[v-cloak] {
  display: none;
}
.cov-vue-date {
  width: 100%;
  .cov-datepicker {
    width: 100%;
    height: 40px;
    padding: 0 5px;
  }
  .cov-picker-box {
    .day {
      &.checked {
        background: $primary-color !important;
      }
    }
  }

  .button-box {
    color: $default-txt !important;
  }
}
.swal2-styled {
  &.swal2-confirm {
    background-color: $primary-color !important;
    border-left-color: $primary-color !important;
    border-right-color: $primary-color !important;
    color: $default-txt;
  }
}
.swal2-styled {
  &.swal2-cancel {
    background-color: #d3d3d3 !important;
    border-left-color: #d3d3d3 !important;
    border-right-color: #d3d3d3 !important;
    color: $default-txt !important;
  }
}
.swal2-actions {
  .swal2-confirm {
    &.swal2-styled {
      font-size: 14px;
    }
  }
  .swal2-cancel {
    &.swal2-styled {
      font-size: 14px;
    }
  }
}
.swal2-content {
  color: $default-txt;
}
#swal2-content {
  font-size: 15px;
}
.error-txt {
  color: $info-txt;
  margin: 3px 0px 0px 0px;
  font-size: 12px;
  &::before {
    content: "";
  }
}
</style>
