export default {
  //localStorage에서 token을 가져오고 localStorage에 있으면 자동로그인도 세팅
  getToken() {
    let tokens = null;
    let autoLogin = false;

    autoLogin = localStorage.getItem("autoLogin") ? true : false;
    tokens = localStorage.getItem(process.env.VUE_APP_STORAGE_NAME)
      ? JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_NAME))
      : { accessToken: null };

    return [tokens, autoLogin];
  },
  // localStorage에 저장
  setToken(tokens, autoLogin) {
    localStorage.setItem(
      process.env.VUE_APP_STORAGE_NAME,
      JSON.stringify(tokens)
    );
    if (autoLogin) {
      localStorage.setItem("autoLogin", 1);
    }
  },
  setTypeImage(data) {
    let image = {};
    switch (data.type) {
      case "normal":
        image = {
          member_img: data.path,
        };
        break;
      case "reviewer":
        image = {
          reviewer_img: data.path,
        };
        break;
      case "seller":
        image = {
          seller_img: data.path,
        };
        break;
      case "exchange":
        image = {
          exchange_img: data.path,
        };
        break;
      default:
        image = {
          member_img: data.path,
        };
        break;
    }
    return image;
  },
  setTypeNickname(data) {
    let nickname = {};
    switch (data.type) {
      case "normal":
        nickname = {
          nickname: data.nickname,
        };
        break;
      case "reviewer":
        nickname = {
          reviewer_nick: data.nickname,
        };
        break;
      case "seller":
        nickname = {
          seller_nick: data.nickname,
        };
        break;
      case "exchange":
        nickname = {
          exchange_nick: data.nickname,
        };
        break;
      default:
        nickname = {
          member_nick: data.nickname,
        };
        break;
    }
    return nickname;
  },
};
